export default {

  namespaced: true,


  state: {
    exports: [],
    export: {}
  },


  mutations: {
    setFilterColumns(state, payload){
      state.filterColumns = payload
    },
    setExports(state, payload){
      state.exports = payload
    },
    setExport(state, payload){
      // check columns and questions
      if (payload.filter_columns == '[]') {
        payload.filter_columns = null
      }
      if (payload.filter_questions == '[]') {
        payload.filter_questions = null
      }
      state.export = payload
    },
    setExportById(state, payload){
      const exportId = payload.export.id
      if (exportId > 0) {
        let item = state.exports.find((x) => {
          return x.id === exportId
        })
        item.status = payload.export.status
        item.nr_cases = payload.export.nr_cases
        item.time_processed = payload.export.time_processed
      }
    }
  },


  getters: {
    getExports: state => {
      return state.exports
    },
    getExport: state => {
      return state.export
    }
  }

}
