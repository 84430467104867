import axios from '@/config/axios'
import store from '@/store'
import moment from '@/config/vue-moment'


export const getTokenFromApi = async () => {
  if (moment().unix() > store.getters['dashboardsState/getExpires']) {
    await axios.get('/api/dashboards/get-token')
      .then(response => {
        store.commit('dashboardsState/setExpires', response.data.expires)
        store.commit('dashboardsState/setToken', response.data.token)
      })
  }
}


export const generateReportTokenFromApi = async (data) => {
  return await axios.post('/api/dashboards/generate-token', {
    bearer: store.getters['dashboardsState/getToken'],
    report: data.report,
    project: data.project,
    user: data.user
  }).then(response => {
    return response
  })
}


export const getReportsInGroupFromApi = async () => {
  await axios.post('/api/dashboards/get-reports-in-group', {
    bearer: store.getters['dashboardsState/getToken']
  })
    .then(response => {
      store.commit('dashboardsState/setReports', response.data.reports)
    })
}


export const detachReportFromApi = async (data) => {
  store.commit('isLoading', true, { root: true })
  await axios.post('/api/dashboards/detach-report', data)
    .then(() => {
      store.commit('isLoading', false, { root: true })
    })
}


export const storeReportToApi = async (data) => {
  store.commit('isLoading', true, { root: true })
  await axios.post('/api/dashboards/store-reports', data)
    .then(() => {
      store.commit('isLoading', false, { root: true })
    })
}
