import axios from "axios"
import store from '@/store'
import router from '@/config/router'
import i18n from '@/config/i18n'


// get the api url from current environment
const buildApiURL = () => {

  // get the full url
  let url_to_array = new URL(window.location.href).hostname.split('.')

  // get client/app
  const client = url_to_array[0]

  // get host from environment first
  // eslint-disable-next-line
  let host = process.env.VUE_APP_API_URL

  if (host) {
    // needed for edge
    // VUE_APP_API_URL=api-edge.taskmaster.nl
    return `//${host}/${client}`
  } else {
    // remove the subdomain (client)
    let build_url = url_to_array.slice(1)

    // add 'api' in front of the url
    build_url.unshift('api')

    // combine back to url
    build_url = build_url.join('.')

    return `//${build_url}/${client}`
  }
}


// create default responses handler
const errorResponseHandler = (error) => {

  if (error.response.status) {

    let responseStatus = error.response.status

    switch (responseStatus) {

      case 500:
        store.commit('setShowSnackbar', ['error', i18n.t('api.notavailable')], { root: true })
        break

      case 404:
        break

      case 422:
        break

      case 419:
        store.commit('setShowSnackbar', ['error', error.response.data.message], { root: true })
        store.commit("authState/setUser", null)
        if (router.currentRoute.name !== 'login' && router.currentRoute.name !== 'reset-password' && router.currentRoute.name !== 'forgot-password') {
          router.push({ name: "login" }).then(() => {return})
        }
        break

      case 403:
        store.commit('setShowSnackbar', ['error', i18n.t(`auth.${error.response.data.message}`)], { root: true })
        break

      case 401:
        store.commit('setShowSnackbar', ['error', i18n.t(`auth.${error.response.data.message}`)], { root: true })
        store.commit("authState/setUser", null)
        router.push({ name: "login" }).then(() => {return})
        break

      case 400:
        let errorText = ''
        if (error.response.data.constructor === ArrayBuffer) {
          const data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
          errorText = data.errors
        } else {
          errorText = error.response.data.message
        }
        store.commit('setShowSnackbar', ['error', errorText], { root: true })
        break

      default:
        store.commit('setShowSnackbar', ['error', error.errors], { root: true })
    }
  }

  store.commit('isLoading', false, { root: true })
  store.commit('isLoadingTable', false, { root: true })
  store.commit('isLoadingButton', false, { root: true })

  if (error.response.status == 422) {
    // we need the response
    return Promise.reject(error.response);
  }

  return Promise.reject(error.response.statusText);
}

// set axios defaults
axios.defaults.baseURL = buildApiURL()
axios.defaults.withCredentials = true
axios.defaults.headers = {
  'X-Requested-With': 'XMLHttpRequest',
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

// apply interceptor on response
axios.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  errorResponseHandler
)

export default axios
