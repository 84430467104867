import i18n from '@/config/i18n';
import {filter, map} from 'lodash-es'

export default {

  getCols() {
    return [
      {
        text: i18n.t('project.projectName'),
        value: {
          col: 'project_name',
          visibleWithType: ['register', 'workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.start_date'),
        value: {
          col: 'case_start',
          visibleWithType: ['register', 'workflow', 'workflow-push', 'workflow-pull'],
          default: true
        }
      },
      {
        text: i18n.t('case.mutation_date'),
        value: {
          col: 'last_history_mutation',
          visibleWithType: ['register', 'workflow', 'workflow-push', 'workflow-pull'],
          default: true
        }
      },
      {
        text: i18n.t('case.assigned_user'),
        value: {
          col: 'assigned_user_name',
          visibleWithType: ['register', 'workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.assigned_team'),
        value: {
          col: 'assigned_user_team',
          visibleWithType: ['register', 'workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.assigned_department'),
        value: {
          col: 'assigned_user_department',
          visibleWithType: ['register', 'workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.mutation_user'),
        value: {
          col: 'user_name',
          visibleWithType: ['register', 'workflow', 'workflow-push', 'workflow-pull'],
          default: true
        }
      },
      {
        text: i18n.t('case.mutation_team'),
        value: {
          col: 'user_team',
          visibleWithType: ['register', 'workflow', 'workflow-push', 'workflow-pull'],
          default: true
        }
      },
      {
        text: i18n.t('case.mutation_department'),
        value: {
          col: 'user_department',
          visibleWithType: ['register', 'workflow', 'workflow-push', 'workflow-pull'],
          default: true
        }
      },
      {
        text: i18n.t('case.case_users_all'),
        value: {
          col: 'case_users_all',
          visibleWithType: ['register', 'workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.case_teams_all'),
        value: {
          col: 'case_teams_all',
          visibleWithType: ['register', 'workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.case_departments_all'),
        value: {
          col: 'case_departments_all',
          visibleWithType: ['register', 'workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.score'),
        value: {
          col: 'score',
          visibleWithType: ['register', 'workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.edit_time'),
        value: {
          col: 'case_edit_time',
          visibleWithType: ['register', 'workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('status.status'),
        value: {
          col: 'case_status',
          visibleWithType: ['workflow', 'workflow-push', 'workflow-pull'],
          default: true
        }
      },
      {
        text: i18n.t('status.type'),
        value: {
          col: 'case_status_type',
          visibleWithType: ['workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.priority'),
        value: {
          col: 'priority',
          visibleWithType: ['workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.deadline'),
        value: {
          col: 'deadline',
          visibleWithType: ['workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.release'),
        value: {
          col: 'release',
          visibleWithType: ['workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.in_time'),
        value: {
          col: 'in_time',
          visibleWithType: ['workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.total_time'),
        value: {
          col: 'case_total_time',
          visibleWithType: ['workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.status_total_time'),
        value: {
          col: 'status_total_time',
          visibleWithType: ['workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.case_in_time'),
        value: {
          col: 'case_in_time',
          visibleWithType: ['workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      },
      {
        text: i18n.t('case.case_edit_count'),
        value: {
          col: 'case_edit_count',
          visibleWithType: ['workflow', 'workflow-push', 'workflow-pull'],
          default: false
        }
      }
    ]
  },

  getColValues() {
    return map(this.getCols(), 'value')
  },

  filterColsBy(type) {
    return filter(this.getCols(), function (val) {
      return val.value.visibleWithType.includes(type)
    })
  },

  filterDefaultColsBy(type) {
    return filter(this.getColValues(), function (val) {
      return val.visibleWithType.includes(type) && val.default === true
    })
  }
}
