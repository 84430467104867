<template>
  <section id="explain">
    <v-row
      no-gutters
      style="white-space: pre-line"
      v-html="$t('user.explain_gr')"
    />

    <v-row class="mb-1">
      <v-spacer />
      <v-speed-dial
        direction="top"
        open-on-hover
        transition="slide-y-reverse-transition"
      >
        <template #activator>
          <v-tooltip
            max-width="50%"
            left
          >
            <template #activator="{ on }">
              <v-btn
                class="mx-2"
                type="submit"
                fab
                color="primary"
                v-on="on"
                @click="$emit('update:explain', false)"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('auth.toLogin') }}</span>
          </v-tooltip>
        </template>
      </v-speed-dial>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "LoginExplanation",
  props: {
    'explain': {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style scoped>

</style>
