export default {
  default: '',
  cases_list: '\
    <h4>Survey-dropdown</h4>\
    <p>The dropdown in the top-right corner shows all accessible surveys. The Cases view focusses on one survey at the time.</p>\
    <h4>Column layout</h4>\
    <p>The Administrator sets the default columns. Custom columns are available via the button Select columns.</p>\
    <h4>Sort cases</h4>\
    <p>The Administrator sets the default sort order. To sort the cases on a custom order just click the column headers.</p>\
    <h4>Case-views</h4>\
    <p>The dropdown in the left top-corner shows the available case views/filters. Based on the type of survey and your permissions the administrator determines your available views.</p>\
    <h4>Extended search</h4>\
    <p>To filter cases based on specific characteristics use the magnifying glass symbol. When blue a filter is applied.</p>\
    <h4>Grey cases</h4>\
    <p>A case has a grey color when:\
      <ul>\
        <li>You can view the case, but you don\'t have permission to edit the case.</li>\
        <li>The case has a release date that has not yet been met</li>\
      </ul>\
    </p>\
    <h4>Why can\'t I view a case?</h4>\
    <p>\
      <ul>\
        <li>You don\'t have permission to the survey the case is part of.</li>\
        <li>The cases shown are restricted via the case view filter.</li>\
        <li>The cases shown are restricted via the extended search options.</li>\
      </ul>\
    </p>\
    <h4>Get Cases</h4>\
    <p>If the Get Cases button is visible, you don\'t have permission to choose cases from the list to edit yourself. Via the Get cases button you will be offered cases according to the administrator\'s prioritization.</p>\
    ',

  cases_detail: '\
    <h4>Copy to clipboard on save</h4>\
    <p>The administrator sets whether Copy to clipboard is available. When active, you will see the button \'copy to clipboard\' and the case input is also copied to the clipboard, so that you can paste it, for example, in CRM.</p>\
    <h4>Save</h4>\
    <p>The case is saved en closed.</p>\
    <h4>Opslaan en verder</h4>\
    <p>When the survey is used for workflow and you have sufficient permissions, the button Save & Continue becomes available. The case is saved and continues to the next status.</p>\
    ',

  users: '\
    <h4>Add new users: 3 steps</h4>\
    <p><ol>\
        <li>Create a user (see details below)</li>\
        <li>Assign survey(s) via permission view (otherwise the user can\'t do anything after the sign in)</li>\
        <li>Send Welcome Email via user view (see details below)</li>\
      </ol></p>\
    <h4>Create user</h4>\
    <p>User (name), Email (=login) and Role are required. Department and Team are added for reporting purposes or used to group users for permissions.</p>\
    <h4>Role</h4>\
    <p>The Role determines which menu tabs the user can access:\
      <ul>\
        <li>Agent:		Cases, Dashboards*</li>\
        <li>Manager:		+ Users, Permissions and Exports</li>\
        <li>Administrator:	+ Surveys, Validations, Imports and Settings</li>\
      </ul>\
    *provided Dashboards are present and users are assigned via permissions.\
    </p>\
    <p><b>Pay Attention:</b> All survey permissions for users are set via the menu tab Permissions.</p>\
    <h4>Batch user import</h4>\
    <p>Load multiple users at once via the Import users button at the top left of the screen. More details are described in that import screen.</p>\
    <p>Click <a href="https://taskmaster.nl/downloads/" target="_blank">here</a> for a template import file.</p>\
    <h4>Welcome Email</h4>\
    <p>Determine if the user receives the Welcome Email immediatly or sent email afterwards via button Sent Welcome Email. The user will receive an email with login details. You can always resent the Welcome email if necessary</p>\
    <h4>Password</h4>\
    <p>Via the link in the Welcome Email the users sets their password. The user can change the password via My profile or via Forgot Password on the login page.</p>\
    <h4>Change Email</h4>\
    <p>An Email cannot be changed, if the email changes or is wrong, a new user has to be created.</p>\
    <h4>Block user</h4>\
    <p>This is used to temporary block an user from login. The case history won\'t be affected.</p>\
    <h4>Delete user</h4>\
    <p>When an user is deleted, the user is blocked for login and the user isn\'t available in the tool anymore. The case history won\'t be affected.</p>\
    <h4>Search and Sort</h4>\
    <p>The search field allows you to search all columns. Use the column headers to sort the results.</p>\
    ',

  projects_list: '\
    <h4>Search and Sort</h4>\
    <p>Use the search field to search all columns. Use the column headers to sort the results.</p>\
    <h4>Copy survey</h4>\
    <p>Select the survey and click the \'Copy survey\' button. The survey layout is copied, not the cases.</p>\
    <h4>Delete survey</h4>\
    <p>Select the survey and click the \'Delete survey\' button. Attention: A deleted survey is no longer available in Cases or Export. If you require the case data export the data first before you delete the survey.</p>\
    <h4>Survey design explanation</h4>\
    <p>Click on the survey to edit, more details are explaned on that page.</p>\
    ',

  projects_details: '\
    <h4>Quick links to:</h4>\
    <p><ol>\
        <li><a href="#help-settings">Settings</a></li>\
        <li><a href="#help-dashboards">Dashboards</a></li>\
        <li><a href="#help-form-view">Form - overview</a></li>\
        <li><a href="#help-form-add">Form - add question</a></li>\
    </ol></p>\
    <h3 id="help-settings">Settings</h3>\
    <h4>Create Survey</h4>\
    <p>To create a new survey first set the Survey name. Next, the settings are available as well as the Tab Form to specify the questions and answers.</p>\
    <h4>Workflow</h4>\
    <p>Activate workflow to add process steps to the survey via the Tab Form and button \'add Status\'. Additional Survey settings will also become avaiable.</p>\
    <h4>Priority (workflow)</h4>\
    <p>Activate Priority to prioritise statusses in workload (Views: My To Do & To Do). Set the priority per status vua the tab Form. When Push cases is activated, cases with max priority are pushed first.</p>\
    <h4>Sort To Do</h4>\
    <p>Set the case order for Push Cases. The sort order is also used for the default case order in views To Do and My To Do.</p>\
    <h4>Restricted case access</h4>\
    <p>From a privacy- or case management- perspective this could be required.<br>When Activated the view Cases is restricted. An user will only see (have access to) his own cases. A Manager user will only see cases from his own Team and/or Department. This setting will overrule the permission setting Show all cases. A Manager user will have a similar restriction for Exports.</p>\
    <h4>Default columns in view Cases</h4>\
    <p>Set the default columns for the view Cases. The available columns are general case-fields and all questions with an active toggle Show as column in Cases.</p>\
    <h4>Deadline and Release</h4>\
    <p>When Deadline and/or Release are activated it is possible to set the deadline and/or release per status.<br>From the moment a case reaches the status the deadline and release are calculated. If a case should wait 3 days for release and should be finished within 2 days. The period to release is +3days and the period to deadline is +5days.\
</p>\
    <h4>Copy to Clipboard</h4>\
    <p>Copies the case information on save to the clipboard. Users can paste this case information in CRM-log or email. Set per Question if it should be copied to the clipboard.</p>\
    <h3 id="help-dashboards">Dashboards</h3>\
    <p>To show dashboards to users:\
      <ol>\
        <li>The processing agreement should allow the use of dashboards. Next the settings will show a Dashboard tab. Contact <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a> to activate this feature.</li>\
        <li>Specify on the tab Dashboards the specific dashboards that should be available for the survey. (default and/or custom dashboard)</li>\
        <li>Specify via Permissions which Users are allowed to see which dashboard.</li>\
      </ol>\
    </p>\
    <h4>Default or custom dashboards</h4>\
    <p>The default dashboards show basic information and charts. Custom dashboards are requested via <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a>.</p>\
    <h3 id="help-form-view">Form - overview</h3>\
    <h4>Questions and Statusses</h4>\
    <p>When Workflow as activated, next to questions you can also add statusses (proces steps).</p>\
    <h4>Copy questions</h4>\
    <p>Copy questions to re-use the settings, question- and answer-text for a <u>new seperate Questions</u>. This is used to speed up generating new new questions with similar answers or similar settings, </p>\
    <h4>Clone questions</h4>\
    <p>Clone questions to re-use the <u>same Question</u> elsewhere in the form. The question is exactly similar, only the routing can differ. This allows users to change the answer later on in the flow. For example for to change the date of an appointment. In Cases or Exports all cloned questions are shown as one column with one answer.<br>When you edit a cloned question or its answers, all clones are edited. Except for the routing, the routing remains specific per cloned question.<br>On the Form tab, the tooltip of a cloned questions shows all related questions with a code (A3 = Question 3 of Status A).\
</p>\
    <h3 id="help-form-add">Form - add question</h3>\
    <h4>Question Type</h4>\
    <p>First select the Question Type for a new question. Add answers (if applicable) via the bottom right blue +button.</p>\
    <h4>Type Multiple Choice or Dropdown</h4>\
    <p>Use Multiple Choice for questions with a few short answers, just 1 click is required to fill the question. When questions have many answers or long answers the dropdown is preferred.</p>\
    <h4>Type Description</h4>\
    <p>This allows to add information or an instruction to the user.</p>\
    <h4>Type Header</h4>\
    <p>This is used to visually devide the form.</p>\
    <h4>Type Users</h4>\
    <p>This question shows all assigned users as answers. It is used:\
      <ol>\
        <li>To assign the case to specific user. My Cases will show the specific case and the column Assigned User is filled. The workload (views To Do & My To Do) is based on the statusses and dependent on the status the case is available for other users.</li>\
        <li>Register assessments for quality monitoring, A user can login and only view his/her own monitor forms. Contact <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a> for all settings.</li>\
      </ol>\
    </p>\
    <h4>Type Score</h4>\
    <p>Add scores to answers to to calculated header values and use it in Dashboards.</p>\
    <h4>Tags</h4>\
    <p>A Tag is an label to group questions for dashboard purposes or to calculate header values. De tag is <u>not</u> visible in the Export-file.</p>\
    <h4>Scores and Header values</h4>\
    <p>It is possible to show scores and other calculated values in the case. This is done via questions of the Header type.\
      <ol>\
        <li>Create a tag in the Header question and activate the toggle Show Value</li>\
        <li>Select the desired calculation </li>\
        <li>Add this tag to all the questions that participate in the calculation.</li>\
        <li>If desired, add scores to the Questions and Answers. (Numeric Answers can also be used in calculations)</li>\
        <li>Email for support to <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a>.</li>\
      </ol>\
    </p>\
    <h4>Copy to clipboard</h4>\
    <p>If the Copy to clipboard toggle is activated, all Questions also get a toggle Copy to clipboard. All new questions get will have this toggle activated by default. Questions that have to be excluded have to be deactivated.</p>\
    <h4>Unique values</h4>\
    <p>For the question types Text and Number, you can enable the Unique Value toggle to prevent cases with duplicate values (manually or via case import). For example, for reference to unique file-ids or customer-ids.</p>\
    <h4>Validations and Formats</h4>\
    <p>Use validations to restrict the text input for specific Questions. For example: the input should have 4 digits and 2 letters or should have 10 digits.<br>Formats are used to determine how to preview a date, time or number. For example: YYYY-MM-DD as date format, or to add an € for an amount.<br>Select a Validation or Format from the list, or create your iwn via te Validation menu tab. Mail for support <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a>.\
</p>\
    <h4>Routing Tips</h4>\
    <p><ol>\
        <li>First define all Statusses and Questions before starting on the routing. (prevents unnecessary adjustments)</li>\
        <li>Show routing-dependent-questions as early as possible. (Route question 1 to questions 3 and 5, Instead of question 1 to 3 and question 3 to 5)</li>\
        <li>To check the routing, use Show Answers for a complete Form overview. When the routing symbol is red, routing is active but no answers have set routing. </a></li>\
    </ol></p>\
    ',

  permissions: '\
    <h4>Roles and Permissions</h4>\
    <p>The <u>Role</u> set in the menu tab Users determines the access to the menu tabs:\
      <ul>\
        <li>Agent:		Cases, Dashboards*</li>\
        <li>Manager:		+ Users, Permissions and Exports</li>\
        <li>Administrator:	+ Surveys, Validations and Settings</li>\
      </ul>\
    *provided Dashboards are present and users are assigned via permissions.<br><br>With just a role a User can\'t use the tool. Via the menu tab Permissions Users are assigned to Surveys and per Survey the specific permissions can be adjusted.\
    </p>\
    <h4>Search on Users and Surveys</h4>\
    <p>If you would like to adjust the Permissions of a specific User, use the User search and deselect the default Survey. If you would like to add new Users to a specific Survey, use the survey search field.</p>\
    <h4>First: Assign Users to Surveys</h4>\
    <p>Select Users via the checkboxes and use the top left button \'Assign\'.</p>\
    <h4>Next: Edit Permissions</h4>\
    <p>Select a specific User or select multiple Users via the checkboxes and use the button \'Edit Selection\'.</p>\
    <h4>Own Cases</h4>\
    <p>The Permissions Show, Edit and Delete can be restricted to just your own cases. Own cases are the cases an user has created, edited or has been assigned to.</p>\
    <h4>Status Permissions</h4>\
    <p>By default Users have the permissions to view and change cases in all statusses. However by deselecting a status, the user will only have permission to View the case in that status and not edit or delete it. If a new status is added to a Survey all assigned users will automatically receive permission for the new status.</p>\
    <h4>Multiple values selected</h4>\
    <p>If multiple users with different initial permissions are edited, \'Multiple valies selected\' is shown. When changed all selected users will receive the new permissions. </p>\
    <h4>Dashboard Permissions</h4>\
    <p>For all available dashboards per Survey, Users have to get access via the dashboard permissions. When checked the user has access to the specific dashboard.</p>\
    ',

  validations: '\
    <h4>Formats and Validations</h4>\
    <p>Use validations to restrict the text input for specific Questions. For example: the input should have 4 digits and 2 letters or should have 10 digits.<br>Formats are used to determine how to preview a date, time or number. For example: YYYY-MM-DD as date format, or to add an € for an amount.\
</p>\
    <h4>Create Validations and Formats</h4>\
    <p>Already several Validations and Formats are available by default. It is possible to create new custom Formats and Validations. Contact <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a> for support.<br><br>If you have experience with the code language you can add new Formats and Validations yourself:\
      <ul>\
        <li>Text input validations: Regex</li>\
        <li>Numeric formats: Numerals.js</li>\
        <li>Date formats: Moments.js</li>\
      </ul>\
    </p>\
    ',

  exports_list: '\
    <h4>Create Export</h4>\
    <p>\
      <ul>\
        <li>Click the blue + button</li>\
        <li>Select the desired Survey and enter an Export name.</li>\
        <li>Specify the columns and period</li>\
        <li>Specify the desired settings</li>\
      </ul>\
    </p>\
    <h4>Columns for month, week, day of time</h4>\
    <p>All datecolumns are formatted as YYYY-MM-DD hh:mm in the Export file. Activate the toggles under Settings to add separate columns with month, week, day of time.</p>\
    <h4>Re-use Exports</h4>\
    <p>The export is saved as a query and can be re-used, if filtered on specific dates only these have to be adjusted.</p>\
    <h4>Export permissions</h4>\
    <p>All Users with the Role Manager and Administrator have access to Exports for all assigned surveys. Exports are only visible to the User that created the Export.</p>\
    <h4>Analyse multiple answers per cell</h4>\
    <p>The survey form has the option to add multiple answers. All answers will be combined in 1 cell. Contact <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a> for support if you have problems generating the desired information.</p>\
    <h4>Data available via Azure</h4>\
    <p>Contact <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a> to make the data available via Azure and to import it in your own BI-systeem.</p>\
    ',

  exports_details: '\
    <h4>Create Export</h4>\
    <p>\
      <ul>\
        <li>Click the blue + button</li>\
        <li>Select the desired Survey and enter an Export name.</li>\
        <li>Specify the columns and period</li>\
        <li>Specify the desired settings</li>\
      </ul>\
    </p>\
    <h4>Columns for month, week, day of time</h4>\
    <p>All datecolumns are formatted as YYYY-MM-DD hh:mm in the Export file. Activate the toggles under Settings to add separate columns with month, week, day of time.</p>\
    <h4>Re-use Exports</h4>\
    <p>The export is saved as a query and can be re-used, if filtered on specific dates only these have to be adjusted.</p>\
    <h4>Export permissions</h4>\
    <p>All Users with the Role Manager and Administrator have access to Exports for all assigned surveys. Exports are only visible to the User that created the Export.</p>\
    <h4>Analyse multiple answers per cell</h4>\
    <p>The survey form has the option to add multiple answers. All answers will becombined in 1 cell. Contact <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a> for support if you have problems generating the desired information.</p>\
    <h4>Data available via Azure</h4>\
    <p>Contact <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a> to make the data available via Azure and to import it in your own BI-systeem.</p>\
    ',

  dashboards: '\
    <h4>Available Dashboards</h4>\
    <p>All available Dashboads are shown in the dropdown. Based on your Dashboard Permissions the available dashboard can differ. If you miss specific dashboards, please contact your Manager or Administator.</p>\
    ',

  imports: '\
    <h4>Create import</h4>\
    <p>This import function allows users to create new cases in bulk via an import file. To determine what settings and data should be used an import query created. The import query can easily be re-used for new import files.\
      <ul>\
        <li>Click the blue + button</li>\
        <li>Select the survey.</li>\
        <li>Select the import file.</li>\
        <li>Check the prefilled import settings and add missing settings</li>\
        <li>Map the imported columns to the survey questions</li>\
        <li>Click import</li>\
        <li>If all checks are passed, every line/row will become a new case</li>\
      </ul></p>\
    <p>To avoid errors, match the column headers of the import file with the questions in the survey.</p>\
    <h4>Import checks</h4>\
    <p>Taskmaster does several checks to prevent corrupt cases. If all checks are passed every line/row will become a new case. Failed checks are displayed per line and can be assessed. After all failures are fixed, the new import file is uploaded again.</p>\
    <h4>Unique cases</h4>\
    <p>To prevent you from loading cases twice, the import function checks for questions where the \'Unique value\' toggle is on for duplicate answers.</p>\
    <h4>Re-use import</h4>\
    <p>When a fixed files setup is imported on a regular base, a previous import query can be re-used. Select the import query and add the new file. As long as the survey questions exist and the selected columns are similar the query can be re-used.</p>\
    <h4>Imported the wrong file</h4>\
    <p>When the wrong file is imported. The newly imported cases can be found in the cases view by searching for the createdate and the user.</p>\
    ',

  settings: '\
    <h4>System language</h4>\
    <p>Determines the format settings for Language, Date, Currency, Decimals and Thousand separator.</p>\
    <h4>Retention periods</h4>\
    <p>The Retention periods are part of the Processing Agreement and can be changed in consultation. Please contact <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a> with your request.</p>\
    ',

  users_import: '\
    <h4>Procedure</h4>\
    <ol>\
    <li>Select a file for import.</li>\
    <li>Check if the number of imported users is correct</li>\
    <li>Map the columns from the file to the user fields.</li>\
    <li>Click import, the file will be checked for errors.</li>\
    <li>When no errors are found, all users will be imported or updated if the email-address is known.</li>\
    <li>New users have to be assigned to surveys. You can do this manually via the permissions.</li>\
    <li>New users have not yet received an invitation. You can do this manually as well.</li>\
    </ol>\
    <h4>Import file requirements</h4>\
    <ul>\
    <li>The filetype is CSV or Excel file</li>\
    <li>The 1st line contains column headers</li>\
    <li>Three columns with information are required <b>User</b>, <b>Role</b> and <b>Email</b>, the columns Team and Department are optional. Other columns are accepted but will be ignored</li>\
    <li>The column Role requires the values \'<b>user</b>\', \'<b>manager</b>\' or \'<b>admin</b>\'.</li>\
    <li>Only data from the mapped columns is imported</li>\
    <li>All rows are imported (empty rows are skipped).</li>\
    <li>The cell value is imported (Excel formulas are supported, but formatting can differ)</li>\
    </ul>\
    ',

  cases_import: '\
    <h4>Procedure</h4>\
    <ol>\
    <li>Select the survey.</li>\
    <li>Select the import file.</li>\
    <li>Check the prefilled import settings and complete missing settings.</li>\
    <li>Map the imported columns to the question fields.</li>\
    <li>Click import, the file will be checked for errors.</li>\
    <li>When no errors are found, new cases are created with the data from the import file.</li>\
    </ol><br/>\
    <h4>Import file requirements</h4>\
    <ul>\
    <li>The file type is CSV or Excel file</li>\
    <li>The 1st line contains column headers</li>\
    <li>Only data from the mapped columns is imported</li>\
    <li>All rows are imported (empty rows result in errors)</li>\
    <li>Maximum of 10,000 rows or 50,000 Answers</li>\
    <li>The cell value is imported (Excel formulas are supported, but formatting can differ)</li>\
    <li>Set dates to text string using the Format Excel formula: <i>=FORMAT(A2;"YYYY-MM-DD")</i></li>\
    </ul><br/>\
    ',

  question_options_import: '\
    <h4>Procedure</h4>\
    <ol>\
    <li>Select a file for import.</li>\
    <li>Map the columns from the file to the answer fields.</li>\
    <li>Click import, the file will be checked for errors.</li>\
    <li>When no errors are found, All current answers are overwritten with the newly imported answers.</li>\
    <li>When the options \'Description\', \'Score\' or \'Routing\' are enabled, the options will become available for import as well.</li>\
    <li>Check the import result and save via Submit.</li>\
    </ol><br/>\
    <h4>Import file requirements</h4>\
    <ul>\
    <li>The filetype is CSV or Excel file</li>\
    <li>The 1st line contains column headers</li>\
    <li>Only data from the mapped columns is imported</li>\
    <li>All rows are imported (empty rows result in errors)</li>\
    <li>The cell value is imported (Excel formulas are supported, but formatting can differ)</li>\
    <li>Scores should be numerical values</li>\
    <li>Routing consists of the following input \'2\', \'B\' or \'2|3|B\'. Use numbers for questions, characters for statuses and a pipe-character as separator. Use ascending sequences</li>\
    </ul><br/>\
    ',

  extended_search_help: '\
    <h4>Filter fields</h4>\
    Are you missing a search field?. Adjust the column layout via \'Select columns\' in the Cases-screen to adjust the available filter fields as well.\
    <br/>\
    <h4>Different filters</h4>\
    Dependent on the type of question, different filters apply:\
    <ul>\
    <li>Dropdown filter: when multiple options are selected, the filter works as an OR-filter.</li>\
    <li>From-To filter</li>\
    <li>Text filter: Only exact matches are found (not case sensitive).</li>\
    </ul>\
    <br/>\
    <h4>Search for part of text</h4>:\
    Search for a text string with the wildcard character (<b>%</b>). For example, with <b>%eve%</b> you will find \'Steven\' and \'Steve\'.\
  '
}
