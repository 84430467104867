<template>
  <div class="align-self-center">
    <v-tooltip  max-width="400px" bottom>
        <template #activator="{ on }">
            <v-btn
                x-small
                fab
                elevation="0"
                color="grey lighten-4"
                class="mx-2 contact-button"
                v-on="on"
                href="https://www.taskmaster.nl/contact" 
                target="_blank"
            >
            <v-icon light>mdi-email</v-icon>
            </v-btn>
        </template>
        <span>{{ $t('contact.contact') }}</span>
    </v-tooltip>
    <v-tooltip  max-width="400px" bottom>
        <template #activator="{ on }">
            <v-btn
                x-small
                fab
                elevation="0"
                color="grey lighten-4"
                class="mx-2 contact-button"
                v-on="on"
                href="https://dashboard.mailerlite.com/forms/306222/79620772684891687/share" 
                target="_blank"
            >
            <v-icon light>mdi-bullhorn</v-icon>
            </v-btn>
        </template>
        <span>{{ $t('contact.newsletter') }}</span>
    </v-tooltip>
    <v-tooltip  max-width="400px" bottom>
        <template #activator="{ on }">
            <v-btn
                x-small
                fab
                elevation="0"
                color="grey lighten-4"
                class="mx-2 contact-button"
                v-on="on"
                href="https://www.linkedin.com/company/89633824/" 
                target="_blank"
            >
            <v-icon light>mdi-linkedin</v-icon>
            </v-btn>
        </template>
        <span>{{ $t('contact.linkedin') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ContactButtons',
}
</script>

<style scoped>
    .contact-button {
        margin-right: 10px !important;
    }
</style>
