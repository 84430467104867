const longDateFormat = {
  LT: 'HH:mm',
  LTS: 'HH:mm:ss',
  L: 'YYYY-ww',
  LL: 'YYYY-MM',
  LLL: 'YYYY-MM-DD',
  lll: 'MM-DD-YYYY',
  LLLL: 'YYYY-MM-DD HH:mm',
  llll: 'MM-DD-YYYY HH:mm:ss',
}

export default longDateFormat;
