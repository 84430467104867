import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'

import authState from "./modules/authState";
import userState from "./modules/userState";
import projectState from "./modules/projectState";
import importState from "./modules/importState";
import exportState from "./modules/exportState";
import caseState from "./modules/caseState";
import permissionsState from "./modules/permissionsState";
import validationState from "./modules/validationState";
import dashboardsState from "./modules/dashboardsState";
import {debounce} from "lodash-es";

Vue.use(Vuex);

const index = new Vuex.Store({

  namespaced: true,


  state: {
    isLoading: false,
    isLoadingTable: false,
    isLoadingButton: false,
    isMobile: false,
    showSnackbar: false,
    typeSnackbar: 'info',
    contentSnackbar: '',
    formDirty: false,
    formDirtyAlert: false,
    lockKey: false,
    appStatus: {}
  },


  mutations: {
    isLoading(state, payload){
      state.isLoading = payload;
    },
    isLoadingTable(state, payload){
      state.isLoadingTable = payload;
    },
    isLoadingButton(state, payload){
      state.isLoadingButton = payload;
    },
    setIsMobile(state, payload){
      state.isMobile = payload;
    },
    setShowSnackbar(state, payload){
      state.showSnackbar = true;
      state.typeSnackbar = payload[0];
      state.contentSnackbar = payload[1];
    },
    resetShowSnackbar(state){
      state.showSnackbar = false;
      state.typeSnackbar = 'info';
      state.contentSnackbar = '';
    },
    setFormDirty(state, payload){
      state.formDirty = payload;
    },
    setFormDirtyAlert(state, payload){
      state.formDirtyAlert = payload;
    },
    setLockKey(state, payload){
      state.lockKey = payload;
    },
    setAppStatus(state, payload){
      let url = new URL(window.location.href).hostname
      payload.app_url = `//${url}`
      state.appStatus = payload;
    },
  },


  getters: {
    isLoading(state){
      return state.isLoading;
    },
    isLoadingTable(state){
      return state.isLoadingTable;
    },
    isLoadingButton(state){
      return state.isLoadingButton;
    },
    isMobile(state){
      return state.isMobile;
    },
    getShowSnackbar(state){
      return state.showSnackbar;
    },
    getTypeSnackbar(state){
      return state.typeSnackbar;
    },
    getContentSnackbar(state){
      return state.contentSnackbar;
    },
    getFormDirty(state){
      return state.formDirty;
    },
    getFormDirtyAlert(state){
      return state.formDirtyAlert;
    },
    getLockKey(state){
      return state.lockKey;
    },
    getAppStatus(state){
      return state.appStatus;
    }
  },


  actions: {
    forceFileDownload({ commit }, payload){
      let url = window.URL.createObjectURL(new Blob([payload.response]));
      let link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', payload.filename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      commit('isLoading', false);
    }
  },


  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['authState'],
      setState: debounce(debouncedSetState, 2000)
    }),
  ],


  modules: {
    authState,
    userState,
    projectState,
    importState,
    exportState,
    caseState,
    permissionsState,
    validationState,
    dashboardsState
  }

});

export default index;


function debouncedSetState (key, state, storage) {

  const jsonState = JSON.stringify({authState: state['authState']})

  // Voila, we still have valid JSON, but now generated 10-50 times faster
  return storage.setItem(key, jsonState)
}
