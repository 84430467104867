<template>
  <div>
    <v-dialog
      :value="FormDirtyAlert"
      color="white"
      width="500px"
      persistent
    >
      <v-card class="pa-3">
        <v-card-title>
          {{ $t('general.attention') }}
        </v-card-title>

        <v-card-text class="">
          <v-row no-gutters>
            <v-col>
              {{ $t('general.notsaved') }}
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            right
            bottom
            text
            @click="logout(true)"
          >
            {{ $t('auth.logout') }}
          </v-btn>
          <v-btn
            right
            bottom
            text
            color="primary"
            @click="FormDirtyAlert = !FormDirtyAlert"
          >
            {{ $t('general.finish') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-app-bar
      color="white"
      dense
      app
    >
      <v-tooltip
        max-width="50%"
        right
        bottom
      >
        <template #activator="{ on }">
          <div v-on="on">
            <v-app-bar-nav-icon
              tabindex="-1"
              class="d-none d-md-block"
              @click.stop="setMini"
            />
            <v-app-bar-nav-icon
              tabindex="-1"
              class="d-md-none"
              @click.stop="setDrawer"
            />
          </div>
        </template>
        <span>{{ $t('general.Menu') }}</span>
      </v-tooltip>

      <v-toolbar-title tabindex="-1">
        {{ screenName }}
      </v-toolbar-title>


      <v-spacer />

      <v-toolbar-title
        tabindex="-1"
        v-if="showTimer"
      >
        <timer :elapsed-time.sync="elapsedTime" />
      </v-toolbar-title>

      <v-spacer />
      <v-menu>
        <template #activator="{ on }">
          <v-btn
            tabindex="-1"
            text
            v-on="on"
            class="d-none d-md-block body-2 text-capitalize"
          >
            {{ getUser.name }}
          </v-btn>
          <v-btn
            tabindex="-1"
            icon
            small
            v-on="on"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-btn
              tabindex="-1"
              depressed
              text
              small
              @click="editProfile"
            >
              {{ $t('user.your_profile') }}
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              tabindex="-1"
              depressed
              text
              small
              @click="logout"
            >
              {{ $t('auth.logout') }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-tooltip
        max-width="50%"
        right
        bottom
        z-index="0"
      >
        <template #activator="{ on }">
          <v-btn
            tabindex="-1"
            v-on="on"
            class="mx-2"
            icon
            x-small
            fab
            outlined
            elevation="0"
            @click="setHelpDrawer"
          >
            <v-icon>mdi-help</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('general.Help') }}</span>
      </v-tooltip>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="mini"
      hide-overlay
      dark
      app
      mobile-breakpoint="960"
    >
      <v-list>
        <v-subheader class="my-5">
          <v-fade-transition>
            <img
              v-if="mini"
              :src="'/img/logo-icon.png'"
            >
          </v-fade-transition>
          <v-expand-transition mode="out-in">
            <img
              v-if="!mini"
              :src="'/img/logo.png'"
            >
          </v-expand-transition>
        </v-subheader>
        <v-list-item-group>
          <v-list-item
            tabindex="-1"
            v-for="(userItem, i) in items"
            :key="i"
            :class="{ 'isactive': userItem.name.toLowerCase() === $route.meta.menu }"
            :id="userItem.name"
            v-show="userItem.meta.role.includes(getUserRole) && checkAvailable(userItem.name)"
            @click="goToRoute(userItem)"
          >
            <v-tooltip
              max-width="50%"
              right
              bottom
            >
              <template #activator="{ on }">
                <v-list-item-icon v-on="on">
                  <v-icon v-text="userItem.meta.icon" />
                </v-list-item-icon>
              </template>
              <span>{{ $t('nav.go_to') }} {{ $t(`nav.${userItem.name}`) }}</span>
            </v-tooltip>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`nav.${userItem.name}`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- HELP CONTAINER -->
    <v-navigation-drawer
      :value="helpdrawer"
      right
      app
      disable-resize-watcher
    >
      <v-subheader>
        <v-spacer />
        <v-btn
          tabindex="-1"
          icon
          @click="helpdrawer = !helpdrawer"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-subheader>
      <v-col v-html="getHelp()" />
    </v-navigation-drawer>

    <profile
      :show.sync="editprofile"
      :user="getUser"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Profile from "./Profile";
import Timer from '@/ui/Timer'
import {bus} from "@/config/event-bus";
import {logoutFromApi, unlockCaseFromApi} from "@/api";
import router from "@/config/router";

export default {
  name: 'Navbar',
  components: {Profile, Timer},
  data: () => ({
    drawer: true,
    mini: true,
    helpdrawer: false,
    editprofile: false,
    FormDirtyAlert: false,
    item: null,
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: 'authState/isLoggedIn',
      getUser: 'authState/getUser',
      getUserRole: 'authState/getUserRole',
      isSuperAdmin: 'authState/isSuperAdmin',
      getCase: 'caseState/getCase',
      getElapsedTime: 'caseState/getElapsedTime',
      getFormDirty: 'getFormDirty',
      getAppStatus: 'getAppStatus',
      getProject: 'projectState/getProject',
    }),

    elapsedTime: {
      get: function () {
        return this.getElapsedTime
      },
      set: function (value) {
        this.$store.commit('caseState/setElapsedTime', value)
      }
    },

    items() {
      let items = [];
      this.$router.options.routes.forEach((route) => {
        if (route.meta.inMenu === 'mainmenu') {
          items.push(route);
        }
      });
      return items;
    },
    screenName() {
      return this.$t('nav.' + this.$route.name);
    },
    showTimer() {
      return (this.$route.name === 'case-edit' || this.$route.name === 'case-new') && this.getProject.hasTimer
    }
  },
  methods: {
    setMini() {
      this.mini = !this.mini;
      bus.$emit('MenuSize', this.mini);
    },
    setDrawer() {
      this.mini = false;
      this.drawer = !this.drawer;
      bus.$emit('MenuSize', this.mini);
    },
    setHelpDrawer() {
      this.helpdrawer = !this.helpdrawer;
    },
    getHelp() {
      let node = null
      switch (this.$route.name) {
        case 'cases':
          node = 'cases_list'
          break
        case 'case-new':
        case 'case-edit':
          node = 'cases_detail'
          break
        case 'users':
          node = 'users'
          break
        case 'projects':
          node = 'projects_list'
          break
        case 'project-new':
        case 'project-edit':
          node = 'projects_details'
          break
        case 'permissions':
          node = 'permissions'
          break
        case 'validations':
          node = 'validations'
          break
        case 'dashboards':
          node = 'dashboards'
          break
        case 'exports':
          node = 'exports_list'
          break
        case 'export-new':
        case 'export-edit':
          node = 'exports_details'
          break
        case 'imports':
          node = 'imports'
          break
        case 'settings':
          node = 'settings'
          break
        default:
          node = 'default'
      }

      return this.$t('help_bar.'+node)
    },
    editProfile() {
      this.editprofile = true;
    },
    logout(force = false) {
      if (!this.getFormDirty || force === true) {
        this.$store.commit('setFormDirty', false, {root: true})
        this.$store.commit('setFormDirtyAlert', false, {root: true})
        if ('id' in this.getCase) {
          unlockCaseFromApi(this.getCase.id)
        }
        logoutFromApi()
      } else {
        this.FormDirtyAlert = true
      }
    },
    goToRoute(item) {
      if (this.$route.name !== item.name) {
        this.$router.push({name: item.name})
      } else {
        window.location.reload()
      }
    },
    checkAvailable(name) {
      let isAvailable = true
      switch (name) {
        case 'dashboards':
          isAvailable = this.getAppStatus.use_dashboards
          break;
        case 'imports':
          isAvailable = (this.getUserRole === 'admin')
          break;
        default:
          isAvailable = true
      }
      return isAvailable
    }
  }
}
</script>

<style scoped>
.username {
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
  font-size: 13px;
}

.theme--dark.v-navigation-drawer {
  background-color: #304156;
}

.theme--dark.v-list-item:not(.v-list-item--disabled),
.theme--dark.v-list-item:not(.v-list-item--disabled) .theme--dark.v-icon {
  color: #b0bec5;
}

.theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::before {
  opacity: 0;
}

.theme--dark.v-list-item.isactive:not(.v-list-item--disabled),
.theme--dark.v-list-item.isactive:not(.v-list-item--disabled) .theme--dark.v-icon {
  color: white;
}

.theme--dark.v-list-item.isactive::before {
  opacity: 0.23;
}

</style>
