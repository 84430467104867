import axios from '@/config/axios'
import store from '@/store'
import i18n from '@/config/i18n'

export const getLocksFromApi = async () => {
  await axios.get('/api/locks/locks').then(response => {
    store.commit('caseState/setLocks', response.data.locks)
  })
}


export const lockCaseFromApi = async (case_id) => {
  return await axios.put(`/api/locks/${case_id}/lock`).then(response => {
    store.commit('caseState/setLocks', response.data.locks)
    return response.data.isLocked
  })
}


export const unlockCaseFromApi = async (case_id) => {
  if(case_id) {
    await axios.put(`/api/locks/${case_id}/unlock`).then(response => {
      store.commit('caseState/setLocks', response.data.locks)
    })
  }
}


export const unlockCasesFromApi = async (data) => {
  store.commit('isLoadingTable', true, { root: true })
  await axios.post('/api/locks/unlock-cases', {
    cases: data.cases,
    project_id: data.project_id
  }).then(response => {
    store.commit('caseState/setLocks', response.data.locks)
    store.commit('isLoadingTable', false, { root: true })
    store.commit('setShowSnackbar', ['info', i18n.t('case.unlockedCases')], { root: true })
  })
}
