import axios from '@/config/axios'
import i18n from '@/config/i18n'
import store from '@/store'


export const getValidationsFromApi = async () => {
  store.commit('isLoadingTable', true, { root: true })
  await axios.get('/api/validations')
    .then(response => {
      store.commit('validationState/setValidations', response.data.validations)
      store.commit('isLoadingTable', false, { root: true })
    })
}


export const updateValidationToApi = async (data) => {
  store.commit('isLoadingButton', true, { root: true })
  return await axios.post('/api/validations/update/' + data.validation.id, data.validation)
    .then(response => {
      store.commit('validationState/setValidations', response.data.validations)
      store.commit('isLoadingButton', false, { root: true })
      store.commit('setShowSnackbar', ['info', i18n.t('validations.stored')], { root: true })
      return response.data.validation
    })
}


export const storeValidationToApi = async (data) => {
  store.commit('isLoadingButton', true, { root: true })
  return await axios.post('/api/validations/store', data.validation)
    .then(response => {
      store.commit('validationState/setValidations', response.data.validations)
      store.commit('isLoadingButton', false, { root: true })
      store.commit('setShowSnackbar', ['info', i18n.t('validations.stored')], { root: true })
      return response.data.validation
    })
}


export const removeValidationsFromApi = async (data) => {
  store.commit('isLoadingTable', true, { root: true })
  await axios.post('/api/validations/remove', {
    validations: data.validations
  })
    .then(response => {
      store.commit('validationState/setValidations', response.data.validations)
      store.commit('isLoadingTable', false, { root: true })
      store.commit('setShowSnackbar', ['info', i18n.t('validations.deletedValidations')], { root: true })
    })
}
