export default {

  namespaced: true,


  state: {
    validations: []
  },


  mutations: {
    setValidations(state, payload){
      state.validations = payload
    }
  },


  getters: {
    getValidations: state => {
      return state.validations
    }
  }

}
