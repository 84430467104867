import axios from "@/config/axios";
import {checkAuthFromApi} from "@/api";
import store from "@/store";


export const getPermissionsFromApi = async (data) => {
  store.commit('isLoading', true, { root: true });
  return await axios.post('/api/permissions', data)
    .then(response => {
      // checkAuthFromApi()
      store.commit("permissionsState/setPermissions", response.data.permissions);
      store.commit('isLoading', false, { root: true });
      return response.data.pagination
    });
}


export const addUserToProjectToApi = async (data) => {
  store.commit('isLoading', true, { root: true });
  await axios.post('/api/permissions/store', data)
    .then(response => {
      store.commit("permissionsState/setPermissions", response.data.permissions);
      store.commit('isLoading', false, { root: true });
    });
}


export const addSelectedUsersToProjectToApi = async (data) => {
  store.commit('isLoading', true, { root: true });
  await axios.post('/api/permissions/add-selected', data)
    .then(response => {
      store.commit("permissionsState/setPermissions", response.data.permissions);
      store.commit('isLoading', false, { root: true });
    });
}


export const removeSelectedUsersToProjectFromApi = async (data) => {
  store.commit('isLoading', true, { root: true });
  await axios.post('/api/permissions/remove-selected', data)
    .then(response => {
      store.commit("permissionsState/setPermissions", response.data.permissions);
      store.commit('isLoading', false, { root: true });
    });
}


export const updatePermissionsToApi = async (data) => {
  store.commit('isLoading', true, { root: true });
  await axios.post('/api/permissions/update', data)
    .then(response => {
      store.commit("permissionsState/setPermissions", response.data.permissions);
      store.commit('isLoading', false, { root: true });
    });
}


export const UpdateSelectedUsersPermissionsToApi = async (data) => {
  store.commit('isLoading', true, { root: true });
  await axios.post('/api/permissions/update-selected', data)
    .then(response => {
      store.commit("permissionsState/setPermissions", response.data.permissions);
      store.commit('isLoading', false, { root: true });
    });
}
