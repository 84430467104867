export default {

  namespaced: true,


  state: {
    users: []
  },


  mutations: {
    setUsers(state, payload){
      state.users = payload
    }
  },


  getters: {
    getUsers: state => {
      return state.users
    }
  }

}
