<template>
  <section id="step-1">
    <ValidationObserver ref="Credentials">
      <v-form @submit.prevent>
        <v-row>
          <v-col cols="10">
            <ValidationProvider
              :name="$t('user.email')"
              rules="required|email"
              :debounce="500"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="email"
                @keyup="capslockState"
                filled
                dense
                hide-details="auto"
                :label="$t('user.email')"
                :placeholder="$t('user.email')"
                :error-messages="errors"
              >
                <template slot="append">
                  <v-tooltip
                    max-width="50%"
                    right
                  >
                    <template #activator="{ on }">
                      <div
                        class="d-inline"
                        v-on="on"
                      >
                        <v-icon v-if="caps">
                          mdi-keyboard-caps
                        </v-icon>
                      </div>
                    </template>
                    <span>{{ $t('auth.CapsLock') }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="10">
            <ValidationProvider
              :name="$t('user.password')"
              rules="required"
              :debounce="500"
              v-slot="{ errors }"
            >
              <v-text-field
                :type="show1 ? 'text' : 'password'"
                v-model="password"
                @keyup="capslockState"
                filled
                dense
                hide-details="auto"
                :label="$t('user.password')"
                :placeholder="$t('user.password')"
                :error-messages="errors"
              >
                <template slot="append">
                  <v-icon
                    v-if="eye_icon"
                    v-html="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click="show1 = !show1"
                  />
                  <v-tooltip
                    max-width="50%"
                    right
                  >
                    <template #activator="{ on }">
                      <div
                        class="d-inline"
                        v-on="on"
                      >
                        <v-icon v-if="caps">
                          mdi-keyboard-caps
                        </v-icon>
                      </div>
                    </template>
                    <span>{{ $t('auth.CapsLock') }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <div class="buttons-holder-login">
          <div class="d-flex align-center flex-column-reverse">
            <v-tooltip
              max-width="50%"
              left
            >
              <template #activator="{ on }">
                <v-btn
                  type="submit"
                  fab
                  color="primary"
                  v-on="on"
                  @click="submitLogin"
                  :loading="isLoadingButton"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('auth.login') }}</span>
            </v-tooltip>

            <v-tooltip
              max-width="50%"
              left
            >
              <template #activator="{ on }">
                <v-btn
                  type="button"
                  fab
                  v-on="on"
                  small
                  @click="forgotPassword"
                >
                  <v-icon>mdi-lock-reset</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('auth.forgotPassword') }}</span>
            </v-tooltip>
          </div>
        </div>
      </v-form>
    </ValidationObserver>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import {loginToApi} from "@/api";

export default {
  name: "LoginCredentials",
  props: {
    'response': {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => ({
    show1: false,
    caps: false,
    eye_icon: false,
    email: "",
    password: "",
    token: null
  }),
  computed: {
    ...mapGetters({
      isLoadingButton: 'isLoadingButton',
    }),
    responseData: {
      get: function () {
        return this.response
      },
      set: function (value) {
        this.$emit('update:response', value)
      }
    },
  },
  methods: {
    capslockState(e) {
      this.caps = false;
      this.eye_icon = true;
      if (typeof e.getModifierState != 'function') {
        return false;
      }
      if (e.getModifierState("CapsLock")) {
        this.caps = true;
      }

    },
    submitLogin() {
      this.$refs.Credentials.validate().then(success => {
        if (!success) {
          return;
        }
        loginToApi({
          email: this.email,
          password: this.password,
          token: this.token
        }).then(result => {
          this.responseData = result
        })

      });
    },
    forgotPassword() {
      this.$router.push({name: 'forgot-password'});
    },
  },
  created() {
    this.token = this.$route.query.token
  }
}
</script>
