import Vue from "vue";
import store from '@/store'
import VueRouter from "vue-router";
import Login from "@/modules/login/Login";
import ForgotPassword from "@/modules/login/ForgotPasswordMail";
import ResetPassword from "@/modules/login/ResetPassword";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    meta: {
      menu: "login",
      inMenu: false,
      requiresAuth: false,
      role: ['user', 'manager', 'admin', 'superadmin'],
      icon: null
    },
    component: Login
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: {
      menu: "login",
      inMenu: false,
      requiresAuth: false,
      role: ['user', 'manager', 'admin', 'superadmin'],
      icon: null
    },
    component: ForgotPassword
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: {
      menu: "login",
      inMenu: false,
      requiresAuth: false,
      role: ['user', 'manager', 'admin', 'superadmin'],
      icon: null
    },
    component: ResetPassword
  },
  {
    path: "/reset-2fa",
    name: "reset-2fa",
    meta: {
      menu: "login",
      inMenu: false,
      requiresAuth: false,
      role: ['user', 'manager', 'admin', 'superadmin'],
      icon: null
    },
    component: Login
  },
  {
    path: "/",
    name: "cases",
    meta: {
      menu: "cases",
      inMenu: 'mainmenu',
      requiresAuth: true,
      role: ['user','manager', 'admin', 'superadmin'],
      icon: 'mdi-format-list-bulleted'
    },
    component: () =>
      import(/* webpackChunkName: "cases" */ "@/modules/cases/CasesView")
  },
  {
    path: "/cases/edit/:id",
    name: "case-edit",
    meta: {
      menu: "cases",
      inMenu: false,
      requiresAuth: true,
      role: ['user', 'manager', 'admin', 'superadmin'],
      icon: null
    },
    component: () =>
      import(/* webpackChunkName: "cases" */ "@/modules/cases/CasesView")
  },
  {
    path: "/cases/new",
    name: "case-new",
    meta: {
      menu: "cases",
      inMenu: false,
      requiresAuth: true,
      role: ['user', 'manager', 'admin', 'superadmin'],
      icon: null
    },
    component: () =>
      import(/* webpackChunkName: "cases" */ "@/modules/cases/CasesView")
  },
  {
    path: "/download/:case/:question/:key/:filename",
    name: "download",
    meta: {
      menu: "cases",
      inMenu: false,
      requiresAuth: true,
      role: ['user', 'manager', 'admin', 'superadmin'],
      icon: null
    },
    component: () =>
      import(/* webpackChunkName: "cases" */ "@/modules/cases/CasesView")
  },
  {
    path: "/users",
    name: "users",
    meta: {
      menu: "users",
      inMenu: 'mainmenu',
      requiresAuth: true,
      role: ['manager', 'admin', 'superadmin'],
      icon: 'mdi-account-multiple'
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "@/modules/users/Users")
  },
  {
    path: "/permissions",
    name: "permissions",
    meta: {
      menu: "permissions",
      inMenu: 'mainmenu',
      requiresAuth: true,
      role: ['manager', 'admin', 'superadmin'],
      icon: 'mdi-key'
    },
    component: () =>
      import(/* webpackChunkName: "permissions" */ "@/modules/permissions/Permissions")
  },
  {
    path: "/projects",
    name: "projects",
    meta: {
      menu: "projects",
      inMenu: 'mainmenu',
      requiresAuth: true,
      role: ['admin', 'superadmin'],
      icon: 'mdi-folder-text'
    },
    component: () =>
      import(/* webpackChunkName: "projects" */ "@/modules/projects/Projects")
  },
  {
    path: "/projects/new",
    name: "project-new",
    meta: {
      menu: "projects",
      inMenu: false,
      requiresAuth: true,
      role: ['admin', 'superadmin'],
      icon: null
    },
    component: () =>
      import(/* webpackChunkName: "projects" */ "@/modules/projects/Projects")
  },
  {
    path: "/projects/edit/:id",
    name: "project-edit",
    meta: {
      menu: "projects",
      inMenu: false,
      requiresAuth: true,
      role: ['admin', 'superadmin'],
      icon: null
    },
    component: () =>
      import(/* webpackChunkName: "projects" */ "@/modules/projects/Projects")
  },
  {
    path: "/validations",
    name: "validations",
    meta: {
      menu: "validations",
      inMenu: 'mainmenu',
      requiresAuth: true,
      role: ['admin', 'superadmin'],
      icon: 'mdi-text-box-check-outline'
    },
    component: () =>
      import(/* webpackChunkName: "projects" */ "@/modules/validations/index")
  },
  {
    path: "/dashboards",
    name: "dashboards",
    meta: {
      menu: "dashboards",
      inMenu: 'mainmenu',
      requiresAuth: true,
      role: ['user', 'manager', 'admin', 'superadmin'],
      icon: 'mdi-chart-line'
    },
    component: () =>
      import(/* webpackChunkName: "dashboards" */ "@/modules/dashboards/DashBoards")
  },
  {
    path: "/imports",
    name: "imports",
    meta: {
      menu: "imports",
      inMenu: 'mainmenu',
      requiresAuth: true,
      role: ['admin', 'superadmin', 'manager'],
      icon: 'mdi-cloud-upload'
    },
    component: () =>
      import(/* webpackChunkName: "exports" */ "@/modules/imports/index")
  },
  {
    path: "/exports",
    name: "exports",
    meta: {
      menu: "exports",
      inMenu: 'mainmenu',
      requiresAuth: true,
      role: ['admin', 'superadmin', 'manager'],
      icon: 'mdi-cloud-download'
    },
    component: () =>
      import(/* webpackChunkName: "exports" */ "@/modules/exports/index")
  },
  {
    path: "/exports/new",
    name: "export-new",
    meta: {
      menu: "exports",
      inMenu: false,
      requiresAuth: true,
      role: ['admin', 'superadmin', 'manager'],
      icon: null
    },
    component: () =>
      import(/* webpackChunkName: "exports" */ "@/modules/exports/index")
  },
  {
    path: "/exports/:id",
    name: "export-edit",
    meta: {
      menu: "exports",
      inMenu: false,
      requiresAuth: true,
      role: ['admin', 'superadmin', 'manager'],
      icon: null
    },
    component: () =>
      import(/* webpackChunkName: "exports" */ "@/modules/exports/index")
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      menu: "settings",
      inMenu: 'mainmenu',
      requiresAuth: true,
      role: ['admin', 'superadmin'],
      icon: 'mdi-cogs'
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/modules/settings/index")
  },
  {
    path: "/:id",
    name: "case",
    meta: {
      menu: "cases",
      inMenu: false,
      requiresAuth: true,
      role: ['user', 'manager', 'admin', 'superadmin'],
      icon: null
    },
    component: () =>
      import(/* webpackChunkName: "cases" */ "@/modules/cases/CasesView")
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach ((to, from, next) => {
  let isLoggedIn = store.getters['authState/isLoggedIn'];
  let isValidFrom = store.getters['authState/getUserRole'] ? to.meta.role.includes(store.getters['authState/getUserRole']) : false;
  let isValidTo = store.getters['authState/getUserRole'] ? to.meta.role.includes(store.getters['authState/getUserRole']) : false;
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (store.getters['getFormDirty']) {
    store.commit('setFormDirtyAlert', to.path)
  } else if (requiresAuth && !isLoggedIn && from.name !== 'login') {
    next({name: 'login'});
  } else if (isLoggedIn && !isValidTo && !isValidFrom) {
    next({name: 'cases'});
  } else if (isLoggedIn && !isValidTo && isValidFrom ) {
    next({name: from.name});
  } else if (isLoggedIn && isValidTo && to.name === 'login' && from.name !== 'login') {
    next({name: 'cases'});
  } else {
    next();
  }
});


export default router;
