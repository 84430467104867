import {filter} from 'lodash-es'


export default {

  namespaced: true,


  state: {
    errors: false,
    user: null,
    isReset: false,
    globalProject: null
  },


  mutations: {
    setErrors(state, payload){
      state.errors = payload
    },
    setUser(state, payload){
      state.user = payload
    },
    setIsReset(state, payload){
      state.isReset = payload
    },
    setGlobalProject(state, payload){
      state.globalProject = payload
    }
  },


  getters: {
    getErrors: state => {
      return state.errors
    },
    isLoggedIn: state => {
      return state.user !== null
    },
    getUser: state => {
      return state.user
    },
    getUserRole: state => {
      if (state.user !== null) {
        return state.user.role
      }
      return false
    },
    isSuperAdmin: state => {
      if (state.user !== null) {
        return state.user.is_super_admin
      }
      return false
    },
    getIsReset: state => {
      return state.isReset
    },
    getGlobalProject: state => {
      return state.globalProject
    },
    getUserProjects: state => {
      if (state.user !== null) {
        return state.user.projects
      }
      return []
    },
    getUserPermissions: state => {
      let project_id = state.globalProject
      let currentProject = filter(state.user.projects, function (p){
        return p.id === project_id
      })
      return typeof currentProject[0] === 'undefined' ? {} : currentProject[0].permissions[0]
    }
  }

}
