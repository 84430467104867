export default {

    namespaced: true,
  
  
    state: {
      imports: [],
      import: {}
    },
  
  
    mutations: {
      setImports(state, payload){
        state.imports = payload
      },
      setImport(state, payload){
        state.import = payload
      },
      setImportById(state, payload){
        const importId = Number(payload.id)
        if (importId > 0) {
          let item = state.imports.find((x) => {
            return x.id === importId
          })
          if (item) {
            item.nr_answers = payload.results.answers
            item.nr_cases = payload.results.cases
            item.nr_errors = payload.results.errors
            item.status = payload.status
            item.time_processed = payload.time_processed
          }
        }
      },
    },
  
  
    getters: {
      getImports: state => {
        return state.imports
      },
      getImport: state => {
        return state.import
      }
    }
  
  }
  