const STATUS_NO_STATUS   = 0;
const STATUS_NO_FILE     = 1;
const STATUS_ERROR       = 2;
const STATUS_INVALID     = 3;
const STATUS_PROCESSING  = 4;
const STATUS_PROCESSED   = 5;
const STATUS_AZURE_ERROR = 6

const STATUS_TYPE_OPEN  = 'open';
const STATUS_TYPE_END   = 'eind';

const SCHEDULE_PERIOD_HOUR   = 'hour';
const SCHEDULE_PERIOD_DAY    = 'day';
const SCHEDULE_PERIOD_WEEK   = 'week';
const SCHEDULE_PERIOD_MONTH  = 'month';

const TIME_IN_TIME  = 'in_time';
const TIME_IN_TIME_VALUE  = 1;
const TIME_TOO_LATE  = 'too_late';
const TIME_TOO_LATE_VALUE  = 0;
const TIME_EMPTY  = 'empty';
const TIME_EMPTY_VALUE  = -1;

// see locales/nl.js
/* eslint-disable */
const DATEPICKER_FORMAT_LLL = 'LLL' // 'YYYY-MM-DD'
const DATEPICKER_FORMAT_lll = 'lll' // 'DD-MM-YYYY'
const DATEPICKER_FORMAT_LLLL = 'LLLL' // 'YYYY-MM-DD HH:mm:SS'
const DATEPICKER_FORMAT_llll = 'llll' // 'DD-MM-YYYY HH:mm:SS'
/* eslint-enable */

const DATEPICKER_FORMAT = DATEPICKER_FORMAT_LLLL;

export default {
  STATUS_NO_STATUS: STATUS_NO_STATUS,
  STATUS_NO_FILE: STATUS_NO_FILE,
  STATUS_ERROR: STATUS_ERROR,
  STATUS_INVALID: STATUS_INVALID,
  STATUS_PROCESSING: STATUS_PROCESSING,
  STATUS_PROCESSED: STATUS_PROCESSED,
  STATUS_AZURE_ERROR: STATUS_AZURE_ERROR,
  STATUS_TYPE_OPEN: STATUS_TYPE_OPEN,
  STATUS_TYPE_END: STATUS_TYPE_END,
  TIME_IN_TIME: TIME_IN_TIME,
  TIME_IN_TIME_VALUE: TIME_IN_TIME_VALUE,
  TIME_TOO_LATE: TIME_TOO_LATE,
  TIME_TOO_LATE_VALUE: TIME_TOO_LATE_VALUE,
  TIME_EMPTY: TIME_EMPTY,
  TIME_EMPTY_VALUE: TIME_EMPTY_VALUE,
  DATEPICKER_FORMAT: DATEPICKER_FORMAT,
  DATEPICKER_FORMAT_DISPLAY: DATEPICKER_FORMAT_llll,
  SCHEDULE_PERIOD_HOUR: SCHEDULE_PERIOD_HOUR,
  SCHEDULE_PERIOD_DAY: SCHEDULE_PERIOD_DAY,
  SCHEDULE_PERIOD_WEEK: SCHEDULE_PERIOD_WEEK,
  SCHEDULE_PERIOD_MONTH: SCHEDULE_PERIOD_MONTH,
};
