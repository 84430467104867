import axios from '@/config/axios'
import store from '@/store'
import i18n from '@/config/i18n'


export const setStatusOrderFromApi = async (data) => {
  await axios.post(`/api/status/${data.project_id}/update-sort-order`, { statuses: data.statuses }).then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('projectState/setProject', response.data.project)
  })
}


export const updateStatusToApi = async (data) => {
  store.commit('isLoadingButton', true, { root: true })
  await axios.post(`/api/status/${data.project_id}/update`, { status: data.status }).then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('projectState/setProject', response.data.project)
    store.commit('isLoadingButton', false, { root: true })
    store.commit('setShowSnackbar', ['info', i18n.t('status.stored')], { root: true })
  })
}


export const storeStatusToApi = async (data) => {
  store.commit('isLoadingButton', true, { root: true })
  await axios.post('/api/status/store', { status: data.status }).then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('projectState/setProject', response.data.project)
    store.commit('isLoadingButton', false, { root: true })
    store.commit('setShowSnackbar', ['info', i18n.t('status.stored')], { root: true })
  })
}


export const removeStatusFromApi = async (data) => {
  store.commit('isLoadingTable', true, { root: true })
  await axios.post(`/api/status/${data.project_id}/remove`, {
    statuses: data.statuses
  }).then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('projectState/setProject', response.data.project)
    store.commit('isLoadingTable', false, { root: true })
    store.commit('setShowSnackbar', ['info', i18n.t('question.deletedStatus')], { root: true })
  })
}
