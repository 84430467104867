import i18n from "@/config/i18n";
import {filter, map, union} from "lodash-es";


export default {
  globals: {
    roles: [
      {
        value: 'user',
        text: i18n.t('user.roles.user')
      },
      {
        value: 'manager',
        text: i18n.t('user.roles.manager')
      },
      {
        value: 'admin',
        text: i18n.t('user.roles.admin')
      }
    ],
    exportTypes: [
      {
        value: 'csv',
        text: i18n.t('export.types.csv')
      },
      {
        value: 'csv-excel',
        text: i18n.t('export.types.csv-excel')
      },
    ]
  },
  methods: {

    // return an array of validations from the validations table, based on question type and validation type
    selectValidations(validations, questionType, validationType) {
      let result = [];
      let rules = filter(validations, function (o) {
        return o.type_validation === validationType && o.type_question.includes(questionType);
      });
      rules.forEach(function (validation) {
        result.push({
          value: validation.id,
          text: validation.label
        });
      });
      return result;
    },

    // Create a routing codes array for drop-down items
    selectRouting(project, current_status, item, routing) {

      // get only the routing_codes from the question routing
      let routes = map(routing, 'routing_code')

      let result = []

      // add routing codes of all questions
      result.push({header: i18n.t('question.questions')});
      current_status.questions.forEach(function (question) {

        // Add routes that are not valid
        if (routes.includes(question.routing_code) && question.routing_code <= Number(item.routing_code)) {
          result.push({
            value: {
              question_view_id: item.id,
              reference: question.id,
              routing_code: question.routing_code,
              type: 'question'
            },
            text: `${question.routing_code} - ${question.question_source.label}`,
            invalid: true
          });
        }

        // Add routes that are applicable
        if (question.routing_code > Number(item.routing_code)) {
          result.push({
            value: {
              question_view_id: item.id,
              reference: question.id,
              routing_code: question.routing_code,
              type: 'question'
            },
            text: `${question.routing_code} - ${question.question_source.label}`
          });
        }
      });

      // only add routing codes of statuses if project is a workflow project
      if (project.isWorkflow && project.statuses.length > 1) {
        result.push({header: i18n.t('status.statuses')});
        project.statuses.forEach(function (status) {
          if (status.id !== current_status.id) {
            result.push({
              value: {
                question_view_id: item.id,
                reference: status.id,
                routing_code: status.routing_code,
                type: 'status'
              },
              text: `${status.routing_code} - ${status.label}`
            });
          }
        });
      }

      // Add deleted questions routing
      if( routing && routing.length ) {
        result.push({header: i18n.t('question.deletedRoutes')});
        routing.forEach(function (route) {

          // Add routes that are not valid
          if (route.routing_code === null) {
            result.push({
              value: {
                question_view_id: route.question_view_id,
                reference: route.reference,
                routing_code: null,
                type: route.type
              },
              text: i18n.t('question.deletedRoute'),
              invalid: true
            });
          }
        })
      }

      return result;
    },

    sortedRoutes(item, routing_code, limit = false) {
      let output = ''
      let i = 0

      let routes = map(item, 'routing_code');

      routes.sort();
      routes.sort((a, b) => a - b);
      routes.forEach(function (route) {
        let classes = Number(route) < routing_code ? 'class="red--text"' : null;
        let seperator = (limit && i === (limit - 1)) || i >= (routes.length - 1) ? '' : ','

        if (limit) {

          if (i < limit) {
            let displayRoute = route === null ? 'X' : route
            output = output + '<span ' + classes + '>' + displayRoute + seperator + ' </span>'
          }
          if (i === limit) {
            output = output + '<span>...</span>'
          }

        } else {
          output = output + '<span ' + classes + '>' + route + seperator + ' </span>'
        }
        i = i + 1
      })

      return output
    }

  }
}
