import axios from '@/config/axios'
import store from '@/store';

export const getImportsFromApi = async () => {
  store.commit('isLoadingTable', true, { root: true })
  await axios.get('/api/imports')
    .then(response => {
      store.commit('importState/setImports', response.data.imports)
      store.commit('isLoadingTable', false, { root: true })
    })
}

export const getImportFromApi = async (data) => {
  store.commit('isLoadingTable', true, { root: true })
  await axios.get('/api/imports/' + data.id)
    .then(response => {
      store.commit('importState/setImport', response.data.import)
      store.commit('isLoadingTable', false, { root: true })
    })
}

export const storeImportToApi = async (data) => {
  return await axios.post('/api/imports/store', data)
    .then(response => {
      store.commit('importState/setImports', response.data.imports)
      return response
    })
}

export const removeImportsFromApi = async (data) => {
  store.commit('isLoadingTable', true, { root: true })
  await axios.post('/api/imports/remove', {
    imports: data.imports
  }).then(response => {
    store.commit('importState/setImports', response.data.imports)
    store.commit('isLoadingTable', false, { root: true })
  })
}