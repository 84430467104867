<template>
  <v-snackbar
    :value="getShowSnackbar"
    bottom
    :color="getTypeSnackbar"
    multi-line
    timeout="5000"
    @input="hideSnackbar"
  >
    <v-list
      dark
      dense
      flat
      tile
      color="transparent"
      v-if="validationErrors"
    >
      <v-list-item-group no-action>
        <v-list-item
          dense
          inactive
          v-for="(value, key) in validationErrors"
          :key="key"
        >
          {{ value }}
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <template #action="{ attrs }">
      <v-btn
        icon
        v-bind="attrs"
        @click="hideSnackbar"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Snackbar",

  computed: {
    ...mapGetters({
      getShowSnackbar: 'getShowSnackbar',
      getTypeSnackbar: 'getTypeSnackbar',
      getContentSnackbar: 'getContentSnackbar'
    }),

    validationErrors() {
      if (typeof this.getContentSnackbar === 'object') {
        return Object.values(this.getContentSnackbar);
      }
      return [this.getContentSnackbar];
    }

  },

  methods: {

    hideSnackbar() {
      this.$store.commit('resetShowSnackbar');
    }

  },

  created() {

    //this.$store.commit('resetShowSnackbar');

  }

}
</script>
