<template>
  <v-dialog
    :value="getFormDirtyAlert"
    color="white"
    width="500px"
    persistent
  >
    <v-card class="pa-3">
      <v-card-title>
        {{ $t('general.attention') }}
      </v-card-title>

      <v-card-text class="">
        <v-row no-gutters>
          <v-col>
            {{ value }}
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          right
          bottom
          text
          @click="closeAlert"
        >
          {{ $t('general.finish') }}
        </v-btn>
        <v-btn
          right
          bottom
          text
          color="primary"
          @click="closeDetail"
        >
          {{ $t('general.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "FormDirty",

  props: {
    value: {
      type: String,
      default: null
    }
  },

  computed: {
    ...mapGetters({
      getFormDirtyAlert: 'getFormDirtyAlert'
    }),
  },

  methods: {
    closeDetail() {
      this.$emit('closeDetail');
    },

    closeAlert() {
      this.$store.commit('setFormDirtyAlert', false, {root: true})
    },
  }
}
</script>
