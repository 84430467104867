// Enable sentry for errors in this application
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import store from '@/store'
import axios from "@/config/axios";
import router from "@/config/router";

// This function will be called for every error
function beforeSend (event, hint) {
  const error = hint.originalException

  if (
    error &&
    error.name &&
    error.name.match(/AuthorizationError|AuthenticationError|NotFoundError/)
  ) {
    // Endpoints that return a 403 or 401 response do not point to an issue, but to someone that is not logged in
    // The 404 NotFoundError occurs on the /me endpoint when the user is no longer logged in
    return null
  }

  if (
    error &&
    error.message &&
    error.message.match(/Failed to update a ServiceWorker|Network Error|Request failed with status code 418/)
  ) {
    // Sometimes a client cannot install the serviceworker, either through a bad connection or e.g. http-connection. Sometimes
    // the serviceworker cannot return something it should return with a proper internet connection. These are not errors
    // we want to log, because we can't do anything with it
    return null
  }

  return event
}

export function initSentry () {
  Sentry.init({
    environment: process.env.VUE_APP_ENVIRONMENT,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [axios.defaults.baseURL, /^\//],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    beforeSend
  })

  store.subscribe(({ type, payload }) => {
    if (type === 'authState/setUser') {
      if (store.getters['authState/isLoggedIn']) {
        const { email, name, role, id } = payload

        Sentry.configureScope(scope => {
          scope.setUser(payload)
        })
      }
    }
  })
}
