import axios from '@/config/axios'
import store from '@/store'
import {sortBy} from 'lodash-es'
import i18n from '@/config/i18n'

export const getCasesPaginationFromApi = async (data) => {
  if (data.project_id !== null) {

    await axios.post(`/api/cases/${data.project_id}/get-cases-pagination`, {
      filter: data.filter,
      options: data.options,
      selectedList: data.selectedList
    })
      .then(response => {
        store.commit('caseState/setPagination', response.data.pagination)
      })
  }
}


export const getCasesFromApi = async (data) => {
  if (data.project_id !== null) {

    store.commit('isLoading', true, { root: true })

    await axios.post(`/api/cases/${data.project_id}/get-cases`, {
      filter: data.filter,
      options: data.options,
      selectedList: data.selectedList
    })
      .then(response => {
        store.commit('caseState/setCases', response.data.cases)
        store.commit('caseState/setLocks', response.data.locks)
        store.commit('isLoading', false, { root: true })
      })
  }
}


export const getCaseFromApi = async (case_id) => {

  store.commit('isLoading', true, { root: true })

  const caseResponse = await axios.get(`/api/cases/${case_id}/get-case`)
  store.commit('caseState/setCase', caseResponse.data.case)

  let elapsedTime = store.getters['caseState/getElapsedTime']
  if (elapsedTime <= caseResponse.data.case.case_edit_time) {
    store.commit('caseState/setElapsedTime', caseResponse.data.case.case_edit_time)
  }

  const statusResponse = await axios.get(`/api/status/${case_id}/get-status`)
  store.commit('caseState/setStatus', statusResponse.data.status)

  const questionsResponse = await axios.get(`/api/questions/${statusResponse.data.status.id}/get-questions`)

  let sorted_questions = sortBy(questionsResponse.data.questions, ['sort_order'])
  store.commit('caseState/setQuestions', sorted_questions)

  const answersResponse = await axios.get(`/api/answers/${case_id}/get-answers`)
  store.commit('caseState/setAnswers', answersResponse.data.answers)

  store.commit('isLoading', false, { root: true })

}

export const getCaseUpdatedAtFromApi = async (case_id) => {
  return await axios.get(`/api/cases/${case_id}/get-case-updated-at`).then(response => {
    if (response.data.message == 'success') {
      return response.data.case
    }
    return []
  })
}


export const getQuestionsFromApi = async (status_id) => {
  store.commit('isLoading', true, { root: true })

  await axios.get(`/api/questions/${status_id}/get-questions`)
    .then(async (response) => {

      let sorted_questions = sortBy(response.data.questions, ['sort_order'])
      store.commit('caseState/setQuestions', sorted_questions)

      store.commit('isLoading', false, { root: true })
    })
}


export const storeCaseToApi = async (data) => {

  store.commit('isLoadingButton', true, { root: true })

  return await axios.post('/api/cases/store-case', data[0], {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'multipart/form-data'
    }
  })
    .then((response) => {
      store.commit('isLoadingButton', false, { root: true })
      return response
    })
}


export const getPushCaseFromApi = async (data) => {

  store.commit('isLoading', true, { root: true })

  await axios.post(`/api/cases/push-case`, data)
    .then(response => {
      store.commit('caseState/setPushCaseId', response.data.case_id)
      store.commit('isLoading', false, { root: true })
    })
}


export const removeCaseFromApi = async (data) => {

  store.commit('isLoading', true, { root: true })

  await axios.post('/api/cases/remove', {
    cases: data.cases,
    project_id: data.project_id
  })
    .then(response => {
      store.commit('caseState/setCases', response.data.cases)
      store.commit('isLoading', false, { root: true })
      store.commit('setShowSnackbar', ['info', i18n.t('case.deletedCases')], { root: true })
    })
}
