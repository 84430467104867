import axios from '@/config/axios'
import i18n from '@/config/i18n'
import store from '@/store'


export const resetTokensFromApi = async (data) => {
  store.commit('isLoadingTable', true, { root: true })
  return await axios.post('/api/users/reset-token', {
    users: data.users
  })
    .then(response => {
      store.commit('userState/setUsers', response.data.users)
      store.commit('isLoadingTable', false, { root: true })
      store.commit('setShowSnackbar', ['info', i18n.t('user.tokensResetted')], { root: true })
      return response.data.user
    })
}


export const getUsersFromApi = async () => {
  await axios.get('/api/users/get-users')
    .then(response => {
      store.commit('userState/setUsers', response.data.users)
    })
}


export const storeUserToApi = async (data) => {
  store.commit('isLoadingButton', true, { root: true })
  return await axios.post('/api/users/store', {
    user: data.user,
    sendMail: data.sendMail
  })
    .then(response => {
      store.commit('userState/setUsers', response.data.users)
      store.commit('isLoadingButton', false, { root: true })

      let message = data.sendMail ? i18n.t('user.stored.withmail') : i18n.t('user.stored.nomail')

      store.commit('setShowSnackbar', ['info', message], { root: true })

      return response.data.user
    })
}


export const updateUsersToApi = async (data) => {
  store.commit('isLoadingTable', true, { root: true })
  await axios.post('/api/users/update-multiple', {
    users: data.users
  })
    .then(response => {
      store.commit('userState/setUsers', response.data.users)
      store.commit('isLoadingTable', false, { root: true })
      store.commit('setShowSnackbar', ['info', i18n.t('user.stored.default')], { root: true })
    })
}


export const updateUserToApi = async (data) => {
  store.commit('isLoadingButton', true, { root: true })
  return await axios.post(`/api/users/${data.user.id}/update`, data.user)
    .then(response => {
      store.commit('userState/setUsers', response.data.users)
      store.commit('isLoadingButton', false, { root: true })
      return response.data.user
    })
}


export const sendMailFromApi = async (data) => {
  store.commit('isLoading', true, { root: true })
  await axios.post('/api/users/send-mail', {
    users: data.users
  })
    .then(() => {
      store.commit('isLoading', false, { root: true })
      store.commit('setShowSnackbar', ['info', i18n.t('user.mailSend')], { root: true })
    })
}


export const removeUsersFromApi = async (data) => {
  store.commit('isLoadingTable', true, { root: true })
  await axios.post('/api/users/remove', {
    users: data.users
  })
    .then(response => {
      store.commit('userState/setUsers', response.data.users)
      store.commit('isLoadingTable', false, { root: true })
      store.commit('setShowSnackbar', ['info', i18n.t('user.deletedUsers')], { root: true })
    })
}


export const exportUsersFromApi = async (data) => {
  store.commit('isLoading', true, { root: true })
  await axios.post('/api/users/export', {
    users: data.users
  }, { responseType: 'arraybuffer' })
    .then(response => {
      store.dispatch('forceFileDownload', { filename: 'users.xlsx', response: response.data }, { root: true })
    })
}


export const importUsersFromApi = async (data) => {
  return await axios.post('/api/users/import', data).then(response => {
    store.commit('userState/setUsers', response.data.users)
    return response
  })
}

export const getProjectUsersFromApi = async (data) => {
  return await axios.get(`/api/projects/${data.projectId}/all-users`).then(response => {
    return response.data.users !== null ? response.data.users : [];
  })
}
