import Vue from "vue";
import axios from '@/config/axios'
import {extend, configure, ValidationProvider, ValidationObserver, setInteractionMode} from "vee-validate";
import {
  required,
  confirmed,
  email,
  min,
  max,
  numeric,
  size,
  regex,
  min_value,
  max_value,
  length,
  ext,
  oneOf
} from "vee-validate/dist/rules";
import i18n from '@/config/i18n'
import moment from '@/config/vue-moment';

configure({
  defaultMessage: (field, values) => {
    return i18n.t(`validation.${values._rule_}`, values);
  }
});

extend("required", required);
extend("confirmed", confirmed);
extend("email", email);
extend("min", min);
extend("max", max);
extend("length", length);
extend("min_value", min_value);
extend("max_value", max_value);
extend("numeric", numeric);
extend("ext", ext);
extend("size", size);
extend("regex", regex);
extend("oneOf", oneOf);


extend('url', {
  validate: (str) => {
    let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  },
  message: i18n.t('validations.validURL')
})

extend('filepath', {
  validate: (str) => {
    let pattern = new RegExp('^[a-zA-Z]:(\\\\\\w+)*([\\]|[.][a-zA-Z]+)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  },
  message: i18n.t('validations.validFilepath')
})

extend('password', {
  validate: (str) => {
    let pattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,30}$', 'g'); // fragment locator
    return !!pattern.test(str);
  },
  message: i18n.t('validations.validPassword')
})

extend("dropdown", {
  validate: (value, arr) => {
    let check = false
    let array = decodeURI(JSON.parse(arr))
    if (typeof value === 'object' && 'value' in value) {
      check = array.includes(String(value.value))
    } else if (Array.isArray(value)) {
      let complete = true;
      value.forEach(function (option) {
        if (!array.includes(option)) {
          complete = false
        }
        check = complete
      })
    } else {
      check = array.includes(value)
    }

    return check
  },
  message: field => field + ' ' + i18n.t('validations.checkdropdown'),
});

extend("checkbox", {
  validate: (value, arr) => {
    let check = false
    let array = decodeURI(JSON.parse(arr))
    if (Array.isArray(value)) {
      let complete = true;
      value.forEach(function (option) {
        if (!array.includes(option.option)) {
          complete = false
        }
        check = complete
      })
    } else {
      check = array.includes(value.option)
    }

    return check
  },
  message: field => field + ' ' + i18n.t('validations.checkdropdown'),
});

// TODO this unique is not globally useable --> use unique_user
extend("unique", {
  validate: value => {
    return axios.post('/api/users/username', {email: value})
      .then(response => {
        if (response.status === 200) {
          return {
            valid: true
          }
        }
      })
      .catch(error => {
        return {
          valid: false
        }
      });
  },
  message: field => field + ' ' + i18n.t('user.must_be_unique'),
});

// Custom rule and message to check username.
extend("unique_user", {
  validate: value => {
    return axios.post('/api/users/username', {email: value})
      .then(response => {
        if (response.status === 200) {
          return {
            valid: true
          }
        }
      })
      .catch(error => {
        return {
          valid: false
        }
      });
  },
  message: field => field + ' ' + i18n.t('user.must_be_unique'),
});

// Custom rule and message to check answers.
extend("unique_answer", {
  params: ['questionId', 'answerId', 'caseId'],
  validate: (value, data) => {
    return axios.post('/api/answers/check-answer', {answer: value, questionId: data.questionId, answerId: data.answerId})
      .then(response => {
        if (response.status === 200) {
          return {
            valid: true
          }
        }
      })
      .catch((error) => {
        data.caseId = error.data.case_id
        return {
          valid: false
        }
      });
  },
  message: i18n.t('validations.answers.must_be_unique') + " in case: {caseId}"
});

extend("date_after", {
  validate: (value, options) => {
    let start = moment(options[0])
    let val = moment(options[1])
    let test = val.isSameOrAfter(start)
    return {
      valid: test
    }
  },
  message: (field, options) => field + ' ' + i18n.t('validations.date_after') + ' ' + moment(options[0]).format('LLL')
});

extend("date_before", {
  validate: (value, options) => {
    let end = moment(options[0], 'YYYY-MM-DD H:mm')
    let val = moment(options[1], 'YYYY-MM-DD H:mm')
    let test = val.isSameOrBefore(end)
    return {
      valid: test
    }
  },
  message: (field, options) => field + ' ' + i18n.t('validations.date_before') + ' ' + moment(options[0]).format('LLL'),
});

extend("date", {
  validate: (value) => {
    let date = moment(value, 'LLL');
    let check = date.isValid();
    return {
      valid: check
    }
  },
  message: (field) => field + ' ' + i18n.t('validations.date'),
});

extend("datetime", {
  validate: (value) => {
    let date = moment(value, 'LLLL');
    let check = date.isValid();
    return {
      valid: check
    }
  },
  message: (field) => field + ' ' + i18n.t('validations.datetime'),
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('Extend', extend);

setInteractionMode('lazy');
