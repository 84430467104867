<template>
  <span>{{ formattedElapsedTime }}</span>
</template>

<script>
import moment from '@/config/vue-moment';

export default {
  name: "Timer",

  props: {
    elapsedTime: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      start: undefined,
      started: undefined,
      timer: undefined,
      focus: false,
      test: undefined
    };
  },

  computed: {

    elapsedTimeLocal: {
      get: function () {
        return this.elapsedTime * 1000
      },
      set: function (value) {
        this.$emit('update:elapsedTime', value / 1000)
      }
    },
    formattedElapsedTime() {
      let elapsed = moment(0, "HH").second(this.elapsedTimeLocal / 1000)
      return elapsed.format('HH:mm:ss')
    },
    currentElapse() {
      let started = this.started
      let now = moment()
      return now.diff(started)
    }
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.elapsedTimeLocal += 1000;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    onFocus() {
      this.focus = true
      let started = this.started
      let now = moment()

      this.elapsedTimeLocal = now.diff(started)
      this.startTimer()
    },
    onBlur() {
      this.focus = false
      this.test = undefined
      this.stopTimer()
    },
    setStarted() {
      this.start = moment(0, "HH").second(this.elapsedTimeLocal / 1000)
      this.started = moment(0, "HH").second(this.elapsedTimeLocal / 1000)
    }
  },

  created() {

    this.setStarted()
    this.startTimer()

  },

  beforeDestroy() {
    this.stopTimer()
  }
};
</script>
