<template>
  <v-container
    class="background-image fill-height"
    fluid
    :style="backgroundStyle"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        md="6"
      >
        <v-card class="elevation-5">
          <v-card-text v-html="getAppStatus.login_text" />
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="8"
        md="5"
        lg="4"
        offset-md="1"
      >
        <v-card class="elevation-5">
          <v-toolbar flat>
            <v-toolbar-title v-if="!responseData">
              {{ $t('auth.login') }}
            </v-toolbar-title>
            <v-toolbar-title v-else-if="responseData.qr_code">
              {{ $t('user.scan_qr_title') }}
            </v-toolbar-title>
            <v-toolbar-title v-else-if="responseData.error">
              {{ $t('auth.error_title') }}
            </v-toolbar-title>
            <v-toolbar-title v-else>
              {{ $t('user.approve_2fa') }}
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-row v-if="getErrors || (responseData && responseData.error)">
              <v-col cols="12">
                <v-banner
                  dark
                  color="error"
                  class="pa-3"
                  v-html="responseData && responseData.error ? $t('auth.' + responseData.message) : $t('auth.' + getErrors)"
                />

                <v-tooltip
                  max-width="50%"
                  left
                >
                  <template #activator="{ on }">
                    <v-btn
                      class="ma-2 float-end"
                      type="submit"
                      fab
                      color="primary"
                      v-on="on"
                      @click="clearResponse()"
                    >
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('auth.toLogin') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <credentials
              v-if="!responseData"
              :response.sync="responseData"
            />

            <two-factor-auth
              v-if="responseData && responseData.twoFactorAuth"
              :response.sync="responseData"
            />
          </v-card-text>
          <v-card-text>
            <contact-buttons />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import Credentials from "@/modules/login/components/Credentials";
import TwoFactorAuth from "@/modules/login/components/twoFactorAuth";
import ContactButtons from "@/modules/login/components/ContactButtons";

export default {
  name: "AppLogin",
  components: {TwoFactorAuth, Credentials, ContactButtons},
  data: () => ({
    responseData: null
  }),
  computed: {
    ...mapGetters({
      isLoadingButton: 'isLoadingButton',
      getErrors: 'authState/getErrors',
      getAppStatus: 'getAppStatus'
    }),

    backgroundStyle() {
      return {backgroundImage: `url("${this.getAppStatus.login_background_image}")`}
    }
  },
  methods: {
    clearResponse() {
      this.responseData = null
    }
  }

}
</script>

<style scoped>
.background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
