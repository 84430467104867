import axios from '@/config/axios'
import store from '@/store'


export const getAppStatusFromApi = async () => {
  return new Promise((resolve, reject) => {
    axios.get('/api/get-app-status').then(response => {
      store.commit('setAppStatus', response.data.data)
      resolve()
    }).catch((error) => {
      reject(error)
    })
  })
}
