import axios from '@/config/axios'
import ExportStatus from '@/const'
import store from '@/store';


const updateExportFromApi = async (data) => {
  data.export.status = ExportStatus.STATUS_PROCESSING
  await axios.post('/api/exports/update/' + data.export.id, data.export)
    .then(response => {
      store.commit('exportState/setExports', response.data.exports)
      store.commit('exportState/setExport', response.data.export)
      store.commit('isLoadingButton', false, { root: true })
    })
}


export const getExportsFromApi = async () => {
  store.commit('isLoadingTable', true, { root: true })
  await axios.get('/api/exports')
    .then(response => {
      store.commit('exportState/setExports', response.data.exports)
      store.commit('isLoadingTable', false, { root: true })
    })
}


export const getExportFromApi = async (data) => {
  store.commit('isLoading', true, { root: true })
  await axios.get('/api/exports/' + data.id)
    .then(response => {
      store.commit('exportState/setExport', response.data.export)
      store.commit('isLoading', false, { root: true })
    })
}


export const storeExportToApi = async (data) => {
  store.commit('isLoadingButton', true, { root: true })
  data.export.status = ExportStatus.STATUS_PROCESSING
  await axios.post('/api/exports/store', data.export)
    .then(response => {
      store.commit('exportState/setExports', response.data.exports)
      store.commit('exportState/setExport', response.data.export)
      store.commit('isLoadingButton', false, { root: true })
    })
}


export const updateExportToApi = async (data) => {
  store.commit('isLoadingButton', true, { root: true })
  // update set last modified date
  data.export.modified = true
  await updateExportFromApi(data)
}


export const reloadExportFromApi = async (data) => {
  store.commit('isLoadingButton', true, { root: true })
  // reload does not set last modified date
  data.export.modified = false
  await updateExportFromApi(data)
}


export const removeExportsFromApi = async (data) => {
  store.commit('isLoadingTable', true, { root: true })
  await axios.post('/api/exports/remove', {
    exports: data.exports
  }).then(response => {
    store.commit('exportState/setExports', response.data.exports)
    store.commit('isLoadingTable', false, { root: true })
  })
}


export const downloadExportsFromApi = async (data) => {
  store.commit('isLoading', true, { root: true })
  await axios.post('/api/exports/download', {
    id: data.id,
    name: data.name
  }, { responseType: 'arraybuffer' })
    .then(response => {
      const fileExtention = '.csv'
      let fileName = data.name + fileExtention
      store.dispatch('forceFileDownload', { filename: fileName, response: response.data }, { root: true })
    })
}

export const getExportDownloadLinkFromApi = async (data) => {
  return await axios.get('/api/exports/' + data.id + '/download-link')
    .then(response => {
      return response.data.download_link
    })
}
