import axios from '@/config/axios'
import store from '@/store'
import i18n from '@/config/i18n'


export const setQuestionsOrderFromApi = async (data) => {
  await axios.post('/api/questions/update-sort-order/' + data.project_id, { questions: data.questions }).then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('projectState/setProject', response.data.project)
  })
}


export const updateQuestionToApi = async (data) => {
  store.commit('isLoading', true, { root: true })
  await axios.post('/api/questions/update/' + data.project_id, { question: data.question }).then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('projectState/setProject', response.data.project)
    store.commit('isLoading', false, { root: true })
  })
}


export const storeQuestionToApi = async (data) => {
  store.commit('isLoading', true, { root: true })
  await axios.post('/api/questions/store/' + data.project_id, { question: data.question }).then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('projectState/setProject', response.data.project)
    store.commit('isLoading', false, { root: true })
    store.commit('setShowSnackbar', ['info', i18n.t('question.stored')], { root: true })
  })
}


export const cloneQuestionsFromApi = async (data) => {
  store.commit('isLoadingTable', true, { root: true })
  await axios.post('/api/questions/clone/' + data.project_id, {
    status_id: data.status_id,
    questions: data.questions
  }).then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('projectState/setProject', response.data.project)
    store.commit('isLoadingTable', false, { root: true })
    store.commit('setShowSnackbar', ['info', i18n.t('question.clonedQuestions')], { root: true })
  })
}


export const duplicateQuestionsFromApi = async (data) => {
  store.commit('isLoadingTable', true, { root: true })
  await axios.post('/api/questions/duplicate/' + data.project_id, {
    status_id: data.status_id,
    questions: data.questions
  }).then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('projectState/setProject', response.data.project)
    store.commit('isLoadingTable', false, { root: true })
    store.commit('setShowSnackbar', ['info', i18n.t('question.duplicatedQuestions')], { root: true })
  })
}


export const removeQuestionsFromApi = async (data) => {
  store.commit('isLoadingTable', true, { root: true })
  await axios.post('/api/questions/remove/' + data.project_id, {
    questions: data.questions
  }).then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('projectState/setProject', response.data.project)
    store.commit('isLoadingTable', false, { root: true })
    store.commit('setShowSnackbar', ['info', i18n.t('question.deletedQuestions')], { root: true })
  })
}


export const getAllQuestionsFromApi = async (data) => {
  await axios.get('/api/questions/' + data.project_id + '/get-all-questions')
    .then(response => {
      store.commit('projectState/setAllQuestions', response.data.questions)
    })
}
