import axios from '@/config/axios'
import store from '@/store'
import DefaultCaseColumns from '@/helpers/defaultCaseColumns'
import i18n from '@/config/i18n'
import moment from "@/config/vue-moment";


export const getProjectFromApi = async (project_id) => {

  if (project_id === null) return

  let project = store.getters['projectState/getProject']

  store.commit('isLoading', true, { root: true })

  const projectResponse = await axios.get(`/api/projects/${project_id}/${moment(project.updated_at).utc().format('YYYY-MM-DD HH:mm:ss')}/get-project`)

  if ('project' in projectResponse.data) {
    store.commit('projectState/setProject', projectResponse.data.project)

    let columns = projectResponse.data.project.columns !== null ? projectResponse.data.project.columns : DefaultCaseColumns.filterDefaultColsBy(projectResponse.data.project.type)
    store.commit('projectState/setUseColumns', columns)

    const statusResponse = await axios.get(`/api/projects/${project_id}/get-statuses`)

    if ('statuses' in statusResponse.data) {
      store.commit('projectState/setStatuses', statusResponse.data.statuses)
    }
  }

  store.commit('isLoading', false, { root: true })

}

export const getProjectsFromApi = async () => {
  store.commit('isLoading', true, {root: true})
  await axios.get('/api/projects').then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('isLoading', false, {root: true})
  })
}

export const getProjectFullFromApi = async (data) => {
  store.commit('isLoading', true, {root: true})
  await axios.get(`/api/projects/${data.id}/get-project`).then(response => {
    store.commit('projectState/setProject', response.data.project)
    store.commit('isLoading', false, {root: true})
  })
}

export const storeProjectToApi = async (data) => {
  store.commit('isLoading', true, {root: true})
  await axios.post('/api/projects/store', data.project).then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('projectState/setProject', response.data.project)
    store.commit('isLoading', false, {root: true})
  })
}

export const updateProjectToApi = async (data) => {
  await axios.post(`/api/projects/${data.project.id}/update`, data.project).then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('projectState/setProject', response.data.project)
  })
}

export const updateProjectColumnsToApi = async (data) => {
  await axios.post(`/api/projects/${data.project_id}/update-columns`, data).then(response => {
    store.commit('projectState/setProjectColumns', response.data.project)
  })
}

export const removeProjectFromApi = async (data) => {
  store.commit('isLoading', true, {root: true})
  await axios.post('/api/projects/remove', {
    project: data.project
  }).then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('isLoading', false, {root: true})
    store.commit('setShowSnackbar', ['info', i18n.t('project.deletedProject')], {root: true})
  })
}


export const duplicateProjectsFromApi = async (data) => {
  store.commit('isLoading', true, {root: true})
  await axios.post('/api/projects/update/duplicate', {
    projects: data.projects
  }).then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('isLoading', false, {root: true})
    store.commit('setShowSnackbar', ['info', i18n.t('project.stored')], {root: true})
  })
}


export const removeProjectsFromApi = async (data) => {
  store.commit('isLoading', true, {root: true})
  await axios.post('/api/projects/remove', {
    projects: data.projects
  }).then(response => {
    store.commit('projectState/setProjects', response.data.projects)
    store.commit('isLoading', false, {root: true})
    store.commit('setShowSnackbar', ['info', i18n.t('project.deletedProjects')], {root: true})
  })
}
