<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
      >
        <v-card class="elevation-5">
          <v-toolbar flat>
            <v-toolbar-title>{{ $t('auth.forgotPassword') }}</v-toolbar-title>
            <v-spacer />
            <v-btn
              icon
              @click="closeDetail"
              tabindex="-1"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text
            v-if="getIsReset"
            class="title my-3"
          >
            <v-row no-gutters>
              {{ $t('auth.isReset') }}
            </v-row>
            <v-row>
              <v-spacer />
              <v-speed-dial
                direction="top"
                open-on-hover
                transition="slide-y-reverse-transition"
              >
                <template #activator>
                  <v-tooltip
                    max-width="50%"
                    left
                  >
                    <template #activator="{ on }">
                      <v-btn
                        class="mx-2"
                        type="submit"
                        fab
                        color="primary"
                        v-on="on"
                        @click="$router.push({name: 'login'})"
                        :loading="isLoadingButton"
                      >
                        <v-icon>mdi-arrow-left</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('auth.toLogin') }}</span>
                  </v-tooltip>
                </template>
              </v-speed-dial>
            </v-row>
          </v-card-text>

          <v-card-text v-if="!getIsReset">
            <v-row v-if="getErrors">
              <v-col cols="12">
                <v-banner
                  dark
                  color="error"
                >
                  {{ $t('auth.'+getErrors) }}
                </v-banner>
              </v-col>
            </v-row>

            <ValidationObserver ref="form">
              <v-form @submit.prevent>
                <v-row>
                  <v-col cols="10">
                    <ValidationProvider
                      :name="$t('user.email')"
                      rules="required"
                      :debounce="500"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="email"
                        filled
                        dense
                        hide-details="auto"
                        :label="$t('user.email')"
                        :placeholder="$t('user.email')"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <div class="buttons-holder-login">
                  <div class="d-flex align-center flex-column-reverse">
                    <v-tooltip
                      max-width="50%"
                      left
                    >
                      <template #activator="{ on }">
                        <v-btn
                          type="submit"
                          fab
                          color="primary"
                          v-on="on"
                          @click="submit"
                          :loading="isLoadingButton"
                        >
                          <v-icon>mdi-check</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('general.submit') }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {mapGetters} from "vuex";
  import {forgotPasswordFromApi, logoutFromApi} from "@/api";

  export default {
    data: () => ({
      email: "",
      password: "",
    }),
    computed: {
      ...mapGetters({
        isLoadingButton: 'isLoadingButton',
        getErrors: 'authState/getErrors',
        getIsReset: 'authState/getIsReset',
      }),
    },
    methods: {
      submit() {
        this.$refs.form.validate().then(success => {
          if (!success) {
            return;
          }
          forgotPasswordFromApi({email: this.email})
        })
      },
      closeDetail() {
        this.$router.push({name: 'login'});
      }
    },
    mounted() {
      logoutFromApi()
      this.$store.commit('authState/setIsReset', false);
    }
  }
</script>
