import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"explain"}},[_c(VRow,{staticStyle:{"white-space":"pre-line"},attrs:{"no-gutters":""},domProps:{"innerHTML":_vm._s(_vm.$t('user.explain_gr'))}}),_c(VRow,{staticClass:"mb-1"},[_c(VSpacer),_c(VSpeedDial,{attrs:{"direction":"top","open-on-hover":"","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VTooltip,{attrs:{"max-width":"50%","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"mx-2",attrs:{"type":"submit","fab":"","color":"primary"},on:{"click":function($event){return _vm.$emit('update:explain', false)}}},on),[_c(VIcon,[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('auth.toLogin')))])])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }