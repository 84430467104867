import Vue from 'vue';
import Vuetify from 'vuetify'


Vue.use(Vuetify)

import nl from 'vuetify/es5/locale/nl'
import en from 'vuetify/es5/locale/en'

export default new Vuetify({
  lang: {
    locales: { nl, en },
    // eslint-disable-next-line 
    current: process.env.VUE_APP_I18N_LOCALE,
  },
  treeShake: true
})
