export default {

  namespaced: true,


  state: {
    expires: null,
    token: null,
    reports: []
  },


  mutations: {
    setExpires(state, payload){
      state.expires = payload
    },
    setToken(state, payload){
      state.token = payload
    },
    setReports(state, payload){
      state.reports = payload
    }
  },


  getters: {
    getExpires: state => {
      return state.expires
    },
    getToken: state => {
      return state.token
    },
    getReports: state => {
      return state.reports
    }
  }

}
