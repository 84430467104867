import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"value":_vm.getFormDirtyAlert,"color":"white","width":"500px","persistent":""}},[_c(VCard,{staticClass:"pa-3"},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('general.attention'))+" ")]),_c(VCardText,{},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._v(" "+_vm._s(_vm.value)+" ")])],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"right":"","bottom":"","text":""},on:{"click":_vm.closeAlert}},[_vm._v(" "+_vm._s(_vm.$t('general.finish'))+" ")]),_c(VBtn,{attrs:{"right":"","bottom":"","text":"","color":"primary"},on:{"click":_vm.closeDetail}},[_vm._v(" "+_vm._s(_vm.$t('general.close'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }