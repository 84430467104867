<template>
  <v-container
    class="background-image fill-height"
    fluid
    style="background-image: url(/img/traffic.jpg)"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
      >
        <v-card class="elevation-5">
          <v-toolbar flat>
            <v-toolbar-title v-if="!responseData">
              {{ $t('auth.resetPassword') }}
            </v-toolbar-title>
            <v-toolbar-title v-else-if="responseData === 422">
              {{ $t('auth.failed') }}
            </v-toolbar-title>
            <v-toolbar-title v-else>
              {{ $t('user.approve_2fa') }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row v-if="getErrors">
              <v-col cols="12">
                <v-banner
                  dark
                  color="error"
                >
                  <div
                    v-for="(item, i) in getErrors"
                    :key="i"
                  >
                    {{ item[0] }}
                  </div>
                </v-banner>
              </v-col>
            </v-row>

            <section
              id="step-1"
              v-if="!responseData"
            >
              <ValidationObserver ref="form">
                <v-form
                  @submit.prevent
                  autocomplete="off"
                >
                  <v-row>
                    <v-col cols="10">
                      <ValidationProvider
                        :name="$t('user.userName')"
                        rules="required"
                        :debounce="500"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="email"
                          filled
                          dense
                          hide-details="auto"
                          :label="$t('user.email')"
                          :placeholder="$t('user.userName')"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="10">
                      <ValidationProvider
                        :name="$t('user.password')"
                        rules="required|password"
                        vid="confirmation"
                        :debounce="500"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          :type="show1 ? 'text' : 'password'"
                          v-model="new_password"
                          autocomplete="new-password"
                          filled
                          dense
                          hide-details="auto"
                          @keyup="capslockState"
                          :label="$t('user.newPassword')"
                          :placeholder="$t('user.newPassword')"
                          :error-messages="errors"
                        >
                          <template slot="append">
                            <v-icon v-if="caps">
                              mdi-keyboard-caps
                            </v-icon>
                            <v-icon
                              v-html="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                              @click="show1 = !show1"
                            />
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="10">
                      <ValidationProvider
                        :name="$t('user.password')"
                        rules="required|confirmed:confirmation"
                        :debounce="500"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          :type="show2 ? 'text' : 'password'"
                          v-model="password_confirmation"
                          filled
                          dense
                          autocomplete="new-password"
                          hide-details="auto"
                          @keyup="capslockState"
                          :label="$t('user.passwordConfirmation')"
                          :placeholder="$t('user.passwordConfirmation')"
                          :error-messages="errors"
                        >
                          <template slot="append">
                            <v-icon v-if="caps">
                              mdi-keyboard-caps
                            </v-icon>
                            <v-icon
                              v-html="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                              @click="show2 = !show2"
                            />
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <div class="buttons-holder-login">
                    <div class="d-flex align-center flex-column-reverse">
                      <v-tooltip
                        max-width="50%"
                        left
                      >
                        <template #activator="{ on }">
                          <v-btn
                            type="submit"
                            fab
                            color="primary"
                            v-on="on"
                            @click="resetPassword"
                            :loading="isLoadingButton"
                          >
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('general.submit') }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-form>
              </ValidationObserver>
            </section>

            <section v-else-if="responseData === 422">
              <v-row>
                <v-col cols="10">
                  {{ $t('auth.failedText') }}
                </v-col>
              </v-row>

              <div class="buttons-holder-login">
                <div class="d-flex align-center flex-column-reverse">
                  <v-tooltip
                    max-width="50%"
                    left
                  >
                    <template #activator="{ on }">
                      <v-btn
                        class="mx-2"
                        type="submit"
                        fab
                        color="primary"
                        v-on="on"
                        @click="$router.push({name: 'login'})"
                        :loading="isLoadingButton"
                      >
                        <v-icon>mdi-arrow-left</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('auth.toLogin') }}</span>
                  </v-tooltip>
                </div>
              </div>
            </section>

            <two-factor-auth
              v-if="responseData && responseData.twoFactorAuth"
              :response.sync="responseData"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import {mapGetters} from "vuex";
import TwoFactorAuth from "@/modules/login/components/twoFactorAuth";
import {loginVerifyToApi, logoutFromApi, resetPasswordFromApi} from "@/api";

export default {
  components: {TwoFactorAuth},
  data: () => ({
    token: "",
    email: "",
    new_password: "",
    password_confirmation: "",
    show1: false,
    show2: false,
    caps: false,
    usersecret: null,
    responseData: null
  }),
  computed: {
    ...mapGetters({
      isLoadingButton: 'isLoadingButton',
      getErrors: 'authState/getErrors',
    }),
  },
  methods: {
    resetPassword() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        resetPasswordFromApi({
          token: this.token,
          email: this.email,
          password: this.new_password,
          password_confirmation: this.password_confirmation
        }).then(result => {
          this.responseData = result
        }).catch(error => {
          if (error.response) {
            this.responseData = error.response.status
          } else {
            this.responseData = null
          }
        })
        this.$nextTick(() => {
            this.$refs.form.reset()
            this.$refs.secret.focus()
          });
      });
    },
    submit2FA() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        loginVerifyToApi({
          secret: this.usersecret,
          user: this.responseData.user.id
        })
      })
    },
    capslockState(e) {
      this.caps = false;
      if (typeof e.getModifierState != 'function') {
        return false;
      }
      if (e.getModifierState("CapsLock")) {
        this.caps = true;
      }

    }
  },
  mounted() {
    logoutFromApi()
    this.token = this.$route.query.token;
  }
}
</script>

<style scoped>
.background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
