<template>
  <section id="step-2">
    <explanation
      v-if="explain"
      :explain.sync="explain"
    />
    <ValidationObserver
      ref="twoFactorAuth"
      v-show="!explain"
    >
      <v-form @submit.prevent>
        <v-row>
          <v-col
            cols="12"
            v-if="responseData.qr_code"
          >
            {{ $t('user.scan_qr') }}<br>
            <img
              :src="responseData.qr_code"
              alt="qr-code"
            >
          </v-col>
          <v-col
            cols="12"
            v-else
          >
            {{ $t('user.info_qr') }}
          </v-col>

          <v-col cols="10">
            <ValidationProvider
              :name="$t('user.secret')"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="usersecret"
                ref="secret"
                filled
                dense
                hide-details="auto"
                :label="$t('user.secret')"
                :placeholder="$t('user.secret')"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>


        <div class="buttons-holder-login">
          <div class="d-flex align-center flex-column-reverse">
            <v-tooltip
              max-width="50%"
              left
            >
              <template #activator="{ on }">
                <v-btn
                  type="submit"
                  fab
                  color="primary"
                  v-on="on"
                  @click="submit2FA"
                  :loading="isLoadingButton"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('auth.login') }}</span>
            </v-tooltip>


            <v-tooltip
              max-width="50%"
              left
            >
              <template #activator="{ on }">
                <v-btn
                  v-if="!responseData.qr_code"
                  fab
                  v-on="on"
                  small
                  @click="reset2FA()"
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('user.lost_qr') }}</span>
            </v-tooltip>
          </div>
        </div>
      </v-form>
    </ValidationObserver>

    <v-col
      cols="8"
      class="mt-2 text-body-2 text-caption text--disabled"
      v-show="!explain"
    >
      <a @click="explain = !explain">{{ $t('user.moreInfo_qr') }}</a>
    </v-col>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import Explanation from "@/modules/login/components/Explanation";
import {loginVerifyToApi, reset2FAFromApi} from "@/api";

export default {
  name: "TwoFactorAuth",
  components: {Explanation},
  props: {
    'response': {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => ({
    explain: false,
    usersecret: null,
  }),
  computed: {
    ...mapGetters({
      isLoadingButton: 'isLoadingButton',
    }),
    responseData: {
      get: function () {
        return this.response
      },
      set: function (value) {
        this.$emit('update:response', value)
      }
    },
  },
  methods: {
    submit2FA() {
      this.$refs.twoFactorAuth.validate().then(success => {
        if (!success) {
          return;
        }
        loginVerifyToApi({
          secret: this.usersecret,
          user: this.responseData.user.id
        })
      });
    },

    reset2FA() {
      this.$refs.twoFactorAuth.reset()
      reset2FAFromApi({user: this.responseData.user.id}).then(() => {
        this.responseData = null
      });
    }
  },
  mounted() {
    this.$refs.secret.focus()
  }
}
</script>

<style scoped>

</style>
