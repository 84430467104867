export default {
  default: '',
  cases_list: '\
    <h4>Project-dropdown</h4>\
    <p>De dropdown rechtsboven toont de projecten waarvoor je rechten hebt. Het scherm toont de cases van één project tegelijk.</p>\
    <h4>Kolomindeling</h4>\
    <p>De Beheerder stelt in welke kolommen het scherm Cases standaard toont. Je kunt de kolomindeling ook zelf aanpassen via de knop Selecteer kolommen.</p>\
    <h4>Sortering cases</h4>\
    <p>De beheerder stelt de standaard sortering van de werkvoorraad in. Je past de sortering aan door op de kolomkoppen te klikken.</p>\
    <h4>Case-filters</h4>\
    <p>De dropdown links bovenin toont case-filters. De beheerder stelt in welke filters voor jou zichtbaar zijn.</p>\
    <h4>Uitgebreid zoeken</h4>\
    <p>Via het vergrootglas-symbool filter je de case-selectie op basis van specifieke kenmerken. De knop is blauw als er een filter actief is.</p>\
    <h4>Grijs weergegeven cases</h4>\
    <p>Een case heeft de kleur grijs als:\
      <ul>\
        <li>Je alleen raadpleeg-, en geen mutatierechten hebt voor de case in die status</li>\
        <li>Het starttijdstip voor de eerstvolgende bewerking nog niet is aangebroken</li>\
      </ul>\
    </p>\
    <h4>Hoe kan het dat ik een case niet zie?</h4>\
    <p>\
      <ul>\
        <li>Je hebt geen rechten tot het project waar de case toe behoort</li>\
        <li>De case valt buiten het geselecteerde case-filter</li>\
        <li>De case valt buiten de ingestelde criteria in Uitgebreid zoeken</li>\
      </ul>\
    </p>\
    <h4>Cases ophalen</h4>\
    <p>Als de knop Cases ophalen zichtbaar is, heb je geen rechten om zelf cases uit de lijst te kiezen om te bewerken. Via de knop Cases ophalen krijg je cases aangeboden volgens de prioritering van de beheerder.</p>\
    ',

  cases_detail: '\
    <h4>Kopieer naar klembord bij opslaan case</h4>\
    <p>De beheerder stelt per project in of bij Case opslaan de invoer ook naar het klembord wordt gekopieerd, zodat je deze in bijvoorbeeld CRM kunt plakken. Je ziet dan de knop Kopieer klembord.</p>\
    <h4>Opslaan</h4>\
    <p>Met de knop Opslaan sla je de case op.</p>\
    <h4>Opslaan en verder</h4>\
    <p>Als je de rechten hebt om ook de volgende status van de case te bewerken, kan je naast Opslaan ook kiezen voor Opslaan en verder. De case opent dan in de volgende status.</p>\
    ',

  users: '\
    <h4>Nieuwe gebruiker inrichten: 3 stappen</h4>\
    <p><ol>\
        <li>Gebruiker aanmaken in scherm Gebruikers (uitleg hieronder)</li>\
        <li>Rechten toekennen in scherm Rechten (anders ziet een gebruiker niets bij inloggen)</li>\
        <li>Welkomstmail versturen in scherm Gebruikers (uitleg hieronder)</li>\
      </ol></p>\
    <h4>Aanmaken gebruiker</h4>\
    <p>Gebruiker (naam), Emailadres (=login) en Rol zijn verplichte velden. Afdeling en Team vul je in als je daar op wilt rapporteren, of groepen gericht wilt autoriseren voor processtappen (statussen) in een workflow.</p>\
    <h4>Rol</h4>\
    <p>De rol bepaalt tot welke schermen de gebruiker toegang heeft:\
      <ul>\
        <li>Agent:	Cases, Dashboards*</li>\
        <li>Manager:	plus Gebruikers, Rechten en Exports</li>\
        <li>Beheerder:	plus Projecten, Validaties, Imports en Instellingen</li>\
      </ul>\
    *mits Dashboards aanwezig zijn en gebruikers daar via Rechten aan zijn gekoppeld\
    </p>\
    <p><b>Let op:</b> de projectrechten van een gebruiker stel je in via het scherm Rechten.</p>\
    <h4>Batchgewijs gebruikers importeren</h4>\
    <p>Via de knop Gebruikers importeren links bovenin het scherm kan je meerdere gebruikers in één keer inladen. De uitleg staat in dat import-scherm beschreven.</p>\
    <p>Klik <a href="https://taskmaster.nl/downloads/" target="_blank">hier</a> voor een template importbestand.</p>\
    <h4>Welkomstmail</h4>\
    <p>Stel voor het verzenden van de welkomstmail met inloggegevens eerst de rechten van de nieuwe Gebruiker in op de pagina Rechten. Pas daarna verzend je de welkomstmail via de mail-knop in het scherm Gebruikers.</p>\
    <p>Je kunt de welkomstmail ook meteen verzenden bij het Opslaan van een nieuwe Gebruiker. Zet dan de toggle \'verstuur email\' aan in de popup Nieuwe gebruiker aanmaken. Als de Gebruiker direct inlogt, ziet deze nog niets omdat er nog geen projectrechten zijn toegekend. </p>\
    <h4>Wachtwoord</h4>\
    <p>De Gebruiker stelt via de link in de welkomstmail een eigen wachtwoord in. De Gebruiker kan dit wijzigen via Mijn profiel, of via Wachtwoord vergeten op de loginpagina.</p>\
    <h4>Emailadres wijzigen</h4>\
    <p>Een emailadres is gelijk aan een Gebruiker en kan je niet wijzigen. Bij een verkeerd aangemaakt emailadres of bij een naamswijziging maak je dus een nieuwe Gebruiker aan.</p>\
    <h4>Gebruiker blokkeren</h4>\
    <p>Dit doe je als je wilt dat een Gebruiker (tijdelijk) niet meer kan inloggen. De Case-historie van deze Gebruiker blijft wel aanwezig.</p>\
    <h4>Gebruiker verwijderen</h4>\
    <p>Na verwijdering kan een Gebruiker niet meer inloggen, en bestaat de Gebruiker niet meer. De Case-historie van deze Gebruiker blijft wel aanwezig.</p>\
    <h4>Zoeken en sorteren</h4>\
    <p>In het veld Zoeken kan je zoeken op Gebruiker, Email, Rol, Team en Afdeling. De resultaten sorteer je via de kolomkoppen.</p>\
    ',

  projects_list: '\
    <h4>Zoeken en sorteren</h4>\
    <p>In het veld Zoeken kan je zoeken over alle kolommen. De resultaten sorteer je via de kolomkoppen.</p>\
    <h4>Project kopiëren</h4>\
    <p>Selecteer het project en druk op de knop Project Kopiëren. Je kopieert hiermee alleen de projectinrichting, niet de cases.</p>\
    <h4>Project verwijderen</h4>\
    <p>Selecteer het project en druk op de knop Project verwijderen. Let op: doordat je het project verwijdert, kan je het project ook niet meer selecteren in bijvoorbeeld Cases of Exports. Als je de case-data wilt exporteren of verwijderen, moet je dit dus doen voordat je het project verwijdert.</p>\
    <h4>Uitleg inrichting projecten</h4>\
    <p>Klik het project aan dat je wilt bewerken, en ga daar naar de helppagina.</p>\
    ',

  projects_details: '\
    <h4>Quicklinks naar:</h4>\
    <p><ol>\
        <li><a href="#help-settings">Instellingen</a></li>\
        <li><a href="#help-dashboards">Dashboards</a></li>\
        <li><a href="#help-form-view">Formulier - overzicht</a></li>\
        <li><a href="#help-form-add">Formulier - vraag toevoegen</a></li>\
    </ol></p>\
    <h3 id="help-settings">Instellingen</h3>\
    <h4>Project aanmaken</h4>\
    <p>Geef in Instellingen minimaal een naam op voor het project. Je kunt nu in het scherm Formulier het project inrichten met vragen en antwoorden.</p>\
    <h4>Workflow</h4>\
    <p>Activeer als je niet alleen informatie wilt vastleggen in een Case, maar ook een workflow wilt inrichten waarin een Case meerdere processtappen (Statussen) doorloopt. In het tabblad Formulier maak je Statussen aan.</p>\
    <p>Klik <a href="https://taskmaster.nl/hoehetwerkt/" target="_blank">hier</a> voor instructies en tips voor de start van een workflow-project.</p>\
    <h4>Prioriteit (workflow)</h4>\
    <p>Zet dit aan als je Statussen wilt prioriteren in de werkvoorraad. In Formulier stel je per Status de prioriteit in. Als je Cases Pushen inschakelt worden Cases met de hoogste prioriteit als eerste aangeboden. Als je dit niet inschakelt ziet de Gebruiker de prioritering wel staan in Cases, maar moet daar zelf naar handelen.</p>\
    <h4>Werkvoorraad sortering</h4>\
    <p>Hier stel je de volgorde in waarin Gebruikers Cases gepushed krijgen (als instelling Cases pushen aan staat), of standaard zien in Cases. In Cases kunnen Gebruikers de sortering zelf aanpassen.</p>\
    <h4>Beperkte Casetoegang</h4>\
    <p>Dit kan een wens zijn vanuit casemanagement, of vanuit privacy bij scores voor kwaliteitsmonitoring.<br>Door deze instelling ziet de Gebruiker in het scherm Cases alleen de eigen Cases, en een Manager alleen de Cases van het eigen Team en/of Afdeling. Deze instelling overruled de instellingen Alle Cases tonen in Rechten. Een Manager wordt ook beperkt bij de Export tot alleen cases van eigen Team en/of Afdeling.\
</p>\
    <h4>Standaard kolommen in Cases instellen</h4>\
    <p>Stel in welke kolommen een Gebruiker standaard ziet in Cases. Je kunt hier kiezen uit algemene Case-velden plus de Vragen waarbij Tonen als kolom in Cases is aangevinkt in Formulier. De Gebruiker kan uit deze kolommen een eigen selectie maken.</p>\
    <h4>Deadline en Vrijgave</h4>\
    <p>Na het inschakelen van de Instelling Deadline stel je per Status in of, en welke Deadline je wilt.<br>Reken vanaf het moment dat de Case in die Status komt; een Case eerst 3 dagen moet wachten en je daarna 3 dagen hebt om hem af te handelen, krijg je Tijd tot vrijgave +3 dagen en Tijd tot deadline +6 dagen.\
</p>\
    <h4>Kopieer naar klembord</h4>\
    <p>Kopieert bij Opslaan de Case-informatie naar het klembord, die de Gebruiker dan kan plakken in bijvoorbeeld een CRM-log of een email. Vragen die niet naar het klembord moeten schakel je per Vraag uit in het Formulier.</p>\
    <h3 id="help-dashboards">Dashboards</h3>\
    <p>Om dashboards te tonen aan gebruikers:\
      <ol>\
        <li>Moet dit in de verwerkersovereenkomst zijn vastgelegd. Pas dan is de instelling Dashboards en het tabblad Dashboards zichtbaar. Neem contact op met <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a> als je dit wilt.</li>\
        <li>Selecteer op het tabblad Dashboards welke dashboards je beschikbaar wilt maken in het scherm Dashboards (standaard en/of maatwerk dashboard)</li>\
        <li>Stel in het scherm Rechten in welke Gebruikers welk dashboard mogen zien</li>\
      </ol>\
    </p>\
    <h4>Standaard of maatwerk dashboard</h4>\
    <p>Het standaard dashboard toont een aantal basisgrafieken per project. Maatwerk dashboards vraag je aan via <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a>.</p>\
    <h3 id="help-form-view">Formulier - overzicht</h3>\
    <h4>Vragen en Statussen</h4>\
    <p>Als Workflow is ingeschakeld bij Instellingen, kan je naast Vragen ook Statussen (processtappen) toevoegen.</p>\
    <h4>Kopiëren</h4>\
    <p>Bij Kopiëren hergebruik je de tekst en instellingen van Vraag en Antwoorden in een nieuwe, <u>aparte Vraag</u>, zodat je als Beheerder (nagenoeg) gelijke Vragen niet steeds helemaal opnieuw hoeft aan te maken.</p>\
    <h4>Klonen</h4>\
    <p>Bij Klonen hergebruik je <u>dezelfde Vraag</u> met Antwoorden, zodat een Gebruiker een Antwoord later in de flow kan wijzigen (bijvoorbeeld datum bij verzetten afspraak, of een aan te vullen opmerkingenveld). In Cases en Exports blijft dit één kolom met één Antwoord.<br>Wijzig je een gekloonde Vraag en/of het Antwoord, dan wijzigen ook alle ‘klonen’. De Routering stel je wel per ‘kloon’ apart in.<br>In het Formulier zie je bij een mouse-over op het Kloon-icoon wat de aangemaakte klonen bij een Vraag zijn (A3 betekent vraag 3 van Status A).\
</p>\
    <h3 id="help-form-add">Formulier - vraag toevoegen</h3>\
    <h4>Type vraag</h4>\
    <p>Selecteer bij een nieuwe vraag eerst het Type vraag. Antwoorden (indien van toepassing) voeg je toe via de blauwe plus rechts onderin het scherm.</p>\
    <h4>Type Meerkeuze of Dropdown</h4>\
    <p>Bij een korte lijst met korte antwoorden kies je Meerkeuze zodat de Gebruiker maar één keer hoeft te klikken. Bij lange en/of meer antwoorden werkt een Dropdown prettiger.</p>\
    <h4>Type Beschrijving</h4>\
    <p>Met dit vraagtype kan je informatie of instructie geven aan de Gebruiker.</p>\
    <h4>Type Header</h4>\
    <p>Hiermee kan je het Formulier visueel onderverdelen.</p>\
    <h4>Type Gebruiker</h4>\
    <p>Deze Vraag toont de namen uit het scherm Gebruikers als Antwoorden. Je kunt hiermee een Case toewijzen aan een Gebruiker voor:\
      <ol>\
        <li>Toewijzen Case aan een specifieke Gebruiker voor afhandeling. De case wordt getoond in de view Mijn Cases, en de kolom assigned gebruiker wordt gevuld. De werkvoorraad werkt op status en afhankelijk van de status is deze case ook beschikbaar voor andere gebruikers</li>\
        <li>Registreren beoordelingen of (kwaliteits-)monitorings op naam, zodat de Gebruiker die via eigen login kan inzien. Neem contact op via <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a> voor de verdere instellingen.</li>\
      </ol>\
    </p>\
    <h4>Type Score</h4>\
    <p>Geef Scores aan Antwoorden zodat je er mee kan rekenen via Header waardes en Dashboards.</p>\
    <h4>Tags</h4>\
    <p>Een Tag is een label of een kenmerk dat je aan een Vraag geeft, om deze gemakkelijk te kunnen groeperen voor de berekening van Waarden in Headers of om te gebruiken in Dashboards. De tag is <u>niet</u> zichtbaar in de Export-file.</p>\
    <h4>Scores en Waarden in Headers</h4>\
    <p>Je kunt Scores en andere berekende waarden ook tonen in Cases. Dit doe je in een Header.\
      <ol>\
        <li>Geef de Vragen of Headers die je in de berekening mee wilt nemen dezelfde Tag.</li>\
        <li>Richt indien gewenst Scores in bij deze Vragen (je kunt ook rekenen met Antwoorden)</li>\
        <li>Maak een Header aan en schakel daarin Toon Waarde in</li>\
        <li>Kies bij Waarde wat je wilt tonen</li>\
        <li>Mail voor hulp naar <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a>.</li>\
      </ol>\
    </p>\
    <h4>Kopieer naar klembord</h4>\
    <p>Als Kopieer naar klembord aan staat bij Instellingen, staat dit bij iedere Vraag standaard aan. Vragen die niet naar het klembord moeten schakel je per Vraag uit.</p>\
    <h4>Unieke waarden afdwingen</h4>\
    <p>Bij de vraagtypen Tekstveld en Getal kan je de toggle Unieke waarde aanzetten om te voorkomen dat cases worden aangemaakt met een reeds bestaand antwoord/waarde. Dit gebruik je als je bijvoorbeeld wilt werken met unieke klant- of dossiernummers.</p>\
    <h4>Validaties en Formats</h4>\
    <p>Met een Validatie stel je eisen aan de invoer in tekstvelden. Bijvoorbeeld 4 cijfers en 2 letters in een postcode-veld, of 10 cijfers zonder streepje in een veld voor telefoonnummer.<br>Met een Format stel je in hoe je een ingevoerde datum, tijd of getal weer wilt geven. Bijvoorbeeld JJJJ-MM-DD als datumnotatie, of een euroteken voor een bedrag.<br>Kies een Validatie of Format uit de lijst, of maak er een aan in het scherm Validaties. Mail voor hulp naar <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a>.\
</p>\
    <h4>Tips Routering</h4>\
    <p><ol>\
        <li>Richt eerst Statussen en Vragen in, en dan Routering (voorkomt onnodig veel aanpassen)</li>\
        <li>Toon vervolgvragen zo vroeg mogelijk (routeer bij vraag 1 naar 3 én 5 als 5 sowieso ook volgt na 1, i.p.v. per vraag van 1 naar 3, en van 3 naar 5</li>\
        <li>Check de routering in Formulier met Toon antwoorden open. Het symbool Routering is als de Instelling Routering bij een Vraag aan staat, maar bij de Antwoorden nog niet is ingevuld</a></li>\
    </ol></p>\
    ',

  permissions: '\
    <h4>Rollen en Rechten</h4>\
    <p>De <u>Rol</u> in het scherm Gebruikers bepaalt de toegang tot de schermen:\
      <ul>\
        <li>Agent:	Cases, Dashboards*</li>\
        <li>Manager:	plus Gebruikers, Rechten en Exports</li>\
        <li>Beheerder:	plus Projecten, Validaties en Instellingen</li>\
      </ul>\
    *mits Dashboards aanwezig zijn en gebruikers daar via Rechten aan zijn gekoppeld<br><br>Met alleen een Rol kan een Gebruiker niets. In deze pagina beheer je de <u>Rechten</u> van Gebruikers per Project.\
    </p>\
    <h4>Zoeken op Gebruiker en Project</h4>\
    <p>Als je Rechten van een individuele Gebruiker wilt aanpassen zoek je op Gebruiker. Als je bestaande Gebruikers aan een nieuw Project wilt koppelen of hun Rechten wilt wijzigen zoek je op Project.</p>\
    <h4>Eerst: Gebruikers aan een Project koppelen</h4>\
    <p>Selecteer de Gebruikers via de checkboxen en druk linksboven op de knop Koppelen.</p>\
    <h4>Daarna: Rechten bewerken</h4>\
    <p>Klik op een individuele Gebruiker of selecteer Gebruikers via de checkboxen en klik linksboven op de knop Selectie bewerken.</p>\
    <h4>Eigen Cases</h4>\
    <p>De rechten Tonen, Wijzigen en Verwijderen kan je ook alleen voor Eigen Cases geven. Dit zijn Cases die de Gebruiker heeft aangemaakt of heeft bewerkt.</p>\
    <h4>Status rechten</h4>\
    <p>Gekoppeld zijn aan een Status betekent dat een Gebruiker een Case kan inzien én bewerken. Een Gebruiker die aan een Project is gekoppeld staat standaard gekoppeld aan alle bijbehorende Statussen. Dit gebeurt ook bij het aanmaken van een nieuwe Status. Ontkoppel een Gebruiker als je deze wilt beperken tot alleen leesrechten voor Cases in die betreffende Status</p>\
    <h4>Meerdere waarden geselecteerd</h4>\
    <p>Bij het bewerken van meerdere Gebruikers tegelijk betekent \'Meerdere waarden geselecteerd\' dat zij op die onderdelen verschillende Rechten hebben.</p>\
    <h4>Dashboard rechten</h4>\
    <p>Om gebruikers toegang te geven tot dashboards dien je per Project de gebruiker Dashboard rechten te geven. De Dashboards kunnen verschillen per project afhankelijk van welke dashboard de beheerder beschikbaar heeft gesteld.</p>\
    ',

  validations: '\
    <h4>Formats en Validaties</h4>\
    <p>Met een Validatie kan je eisen stellen aan de invoer in tekstvelden. Bijvoorbeeld 4 cijfers en 2 letters in een postcode-veld, of 10 cijfers zonder streepje in een veld voor telefoonnummer.<br><br>Met een Format stel je in hoe je een ingevoerde datum, tijd of getal weer wilt geven. Bijvoorbeeld JJJJ-MM-DD als datumnotatie, of een euroteken voor een bedrag.\
</p>\
    <h4>Aanmaken Validaties en Formats</h4>\
    <p>Je kunt kiezen uit een aantal standaard Validaties en Formats. Wil je een nieuwe aanmaken die er niet bij staat? Neem voor hulp contact op via <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a>.<br><br>Je kunt ze ook zelf aanmaken als je de codetaal beheerst:\
      <ul>\
        <li>Tekstinvoer validaties: Regex</li>\
        <li>Numerieke formats en getalnotaties: Numerals.js</li>\
        <li>Datum formats: Moments.js</li>\
      </ul>\
    </p>\
    ',

  exports_list: '\
    <h4>Export aanmaken</h4>\
    <p>\
      <ul>\
        <li>Druk op de blauwe plusknop</li>\
        <li>Selecteer het Project waarvan je een Export wilt en geef de Export een naam</li>\
        <li>Specificeer onder Algemeen en Vragen de gewenste kolommen en periode</li>\
        <li>Selecteer de gewenste instellingen</li>\
      </ul>\
    </p>\
    <h4>Kolom voor maand, week, dag of tijd</h4>\
    <p>De datumvelden van de Algemene casegegevens staan als JJJJ-MM-DD hh:mm in de Exportfile. Selecteer bij Instellingen of je aparte kolommen wilt toevoegen voor maand, week, dag of tijd.</p>\
    <h4>Exports hergebruiken</h4>\
    <p>Als je periodiek dezelfde export nodig hebt, pas je in de bestaande Export de datum aan en slaat de Export op.</p>\
    <h4>Exportrechten</h4>\
    <p>Gebruikers met de Rol Manager en Beheerder in het scherm Gebruikers kunnen Exports aanmaken voor Projecten waar zij aan gekoppeld zijn. Een Export is alleen zichtbaar voor de Gebruiker die de Export heeft aangemaakt.</p>\
    <h4>Analyseren van meerdere antwoorden per cel</h4>\
    <p>Het Formulier biedt de optie om bij een Vraag meerdere antwoorden te kunnen selecteren. De gekozen antwoorden komen in één cel terecht. Lees <a href="https://taskmaster.nl/rapportrenovermeerdereantwoordenpercel/" target="_blank">hier</a> hoe je hier in Excel analyses mee maakt.</p>\
    <h4>Exportbestanden verversen in eigen rapportage</h4>\
    <p>Als je Taskmaster data gebruikt in een eigen rapportage in bijvoorbeeld Excel, PowerBI of Google, kan je exportbestanden automatisch periodiek verversen en deze uit Taskmaster ophalen met een link.</p>\
    <p>\
      <ul>\
        <li>Open de exportquery en activeer de toggle Zet planning aan</li>\
        <li>Stel de gewenste frequentie in voor automatisch verversen en sla de query op</li>\
        <li>Selecteer de query in het scherm Exports en klik op Link naar exportbestand creëren</li>\
        <li>De link blijft een jaar geldig en bruikbaar om het bestand mee op te halen</li>\
	<li>Een nieuw ververst bestand blijft drie dagen beschikbaar</li>\
        <li>Het automatisch verversen stopt bij het blokkeren van de gebruiker of het wijzigen van het project</li>\
        <li>Herstart het automatisch verversen door de exportquery te openen en deze weer op te slaan</li>\
      </ul>\
    </p>\
    <h4>De link gebruiken in Excel of in PowerBI</h4>\
    <p>\
      <ul>\
        <li>Ga in Excel of PowerBI naar <b>Gegevens ophalen</b> en dan <b>Web</b> (soms via Overige)</li>\
        <li>Voeg de link in (standaard staat deze op basis, en dat is goed)</li>\
        <li>Selecteer <b>OK</b></li>\
        <li>Soms moet je bevestigen dat je anoniem verbinding wilt maken</li>\
        <li>Deze methode werkt alleen voor Windows, en niet op een Mac</li>\
      </ul>\
    </p>\
    <h4>Data rechtstreeks ophalen uit Azure</h4>\
    <p>Neem contact op via <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a> om data rechtstreeks vanuit Azure te importeren in het eigen BI-systeem.</p>\
    ',

  exports_details: '\
    <h4>Export aanmaken</h4>\
    <p>\
      <ul>\
        <li>Druk op de blauwe plusknop</li>\
        <li>Selecteer het Project waarvan je een Export wilt en geef de Export een naam</li>\
        <li>Specificeer onder Algemeen en Vragen de gewenste kolommen en periode</li>\
        <li>Selecteer de gewenste instellingen</li>\
      </ul>\
    </p>\
    <h4>Kolom voor maand, week, dag of tijd</h4>\
    <p>De datumvelden van de Algemene casegegevens staan als JJJJ-MM-DD hh:mm in de Exportfile. Selecteer bij Instellingen of je aparte kolommen wilt toevoegen met maand, week, dag of tijd.</p>\
    <h4>Exports hergebruiken</h4>\
    <p>Als je periodiek dezelfde export nodig hebt, pas je in de bestaande Export de datum aan en slaat de Export op.</p>\
    <h4>Exportrechten</h4>\
    <p>Gebruikers met de Rol Manager en Beheerder in het scherm Gebruikers kunnen Exports aanmaken voor Projecten waar zij aan gekoppeld zijn. Een Export is alleen zichtbaar voor de Gebruiker die de Export heeft aangemaakt.</p>\
    <h4>Analyseren van meerdere antwoorden per cel</h4>\
    <p>Het Formulier biedt de optie om bij een Vraag meerdere antwoorden te kunnen selecteren. De gekozen antwoorden komen in één cel terecht. Lees <a href="https://taskmaster.nl/rapportrenovermeerdereantwoordenpercel/" target="_blank">hier</a> hoe je hier in Excel analyses mee maakt.</p>\
    <h4>Exportbestanden verversen in eigen rapportage</h4>\
    <p>Als je Taskmaster data gebruikt in een eigen rapportage in bijvoorbeeld Excel, PowerBI of Google, kan je exportbestanden automatisch periodiek verversen en deze uit Taskmaster ophalen met een link.</p>\
    <p>\
      <ul>\
        <li>Open de exportquery en activeer de toggle Zet planning aan</li>\
        <li>Stel de gewenste frequentie in voor automatisch verversen en sla de query op</li>\
        <li>Selecteer de query in het scherm Exports en klik op Link naar exportbestand creëren</li>\
        <li>De link blijft een jaar geldig en bruikbaar om het bestand mee op te halen</li>\
	<li>Een nieuw ververst bestand blijft drie dagen beschikbaar</li>\
        <li>Het automatisch verversen stopt bij het blokkeren van de gebruiker of het wijzigen van het project</li>\
        <li>Herstart het automatisch verversen door de exportquery te openen en deze weer op te slaan</li>\
      </ul>\
    </p>\
    <h4>De link gebruiken in Excel of in PowerBI</h4>\
    <p>\
      <ul>\
        <li>Ga in Excel of PowerBI naar <b>Gegevens ophalen</b> en dan <b>Web</b> (soms via Overige)</li>\
        <li>Voeg de link in (standaard staat deze op basis, en dat is goed)</li>\
        <li>Selecteer <b>OK</b></li>\
        <li>Soms moet je bevestigen dat je anoniem verbinding wilt maken</li>\
        <li>Deze methode werkt alleen voor Windows, en niet op een Mac</li>\
      </ul>\
    </p>\
    <h4>Data rechtstreeks ophalen uit Azure</h4>\
    <p>Neem contact op via <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a> om data rechtstreeks vanuit Azure te importeren in het eigen BI-systeem.</p>\
    ',

  dashboards: '\
    <h4>Getoonde Dashboards</h4>\
    <p>In de dropdown staan de beschikbare Dashboards waar jij rechten voor hebt. Vraag jouw Manager of de Beheerder als je een Dashboard denkt te missen.</p>\
    ',

  imports: '\
    <h4>Import aanmaken</h4>\
    <p>Via de importfunctie kan je batchgewijs nieuwe cases aanmaken in Taskmaster door het inladen van een bestand. Om te zorgen dat de cases met de juiste gegevens in de juiste status terecht komen, maak je een (herbruikbare) importquery aan.\
      <ul>\
        <li>Druk op de blauwe plus</li>\
        <li>Selecteer het Project waar je nieuwe cases aan wilt toevoegen</li>\
        <li>Selecteer het Excelbestand met de importgegevens</li>\
        <li>Controleer de vooringevulde Importinstellingen en vul ze aan</li>\
        <li>Selecteer bij de getoonde vragen de bijbehorende kolommen uit het importbestand</li>\
        <li>Druk op Importeer</li>\
      </ul></p>\
    <p>Om fouten te voorkomen, is het verstandig om de kolommen in het importbestand dezelfde namen te geven als de vragen in het projectformulier.</p>\
    <h4>Import controles</h4>\
    <p>Taskmaster voert eerst een aantal controles uit voordat de nieuwe cases worden aangemaakt. Alleen als aan alle controles wordt voldaan worden de cases ingeladen. Afwijkingen worden per regel uit het importbestand toegelicht. Nadat je deze hebt hersteld, laad je het hele bestand opnieuw in.</p>\
    <h4>Alleen unieke cases inladen</h4>\
    <p>Om te voorkomen dat je cases dubbel inlaadt, checkt de importfunctie bij vragen waarbij in het projectscherm de toggle \'Unieke waarde\' aan staat of er al cases met die waarde in Taskmaster staan.</p>\
    <h4>Imports hergebruiken</h4>\
    <p>Als je vaker dezelfde werklijsten importeert, selecteer je de bestaande importquery en voorziet deze van een nieuw importbestand. Dit kan je zo doen zolang je geen wijzigingen aanbrengt in het projectformulier en de structuur van het importbestand.</p>\
    <h4>Verkeerd bestand geïmporteerd?</h4>\
    <p>Heb je een onjuist bestand geïmporteerd? Selecteer de cases dan in het scherm Cases door te zoeken op Aanmaakdatum/tijdstip en de Gebruiker en verwijder ze.</p>\
    ',

  settings: '\
    <h4>Systeemtaal</h4>\
    <p>Bepaalt instellingen voor taal, datum, valuta, decimale scheidingsteken en duizendtal-scheidingsteken.</p>\
    <h4>Bewaartermijnen</h4>\
    <p>De bewaartermijnen maken onderdeel uit van de Verwerkersovereenkomst, en zijn in overleg te wijzigen. Neem hiervoor contact op met <a href="mailto:info@taskmaster.nl">info@taskmaster.nl</a>.</p>\
    ',

  users_import: '\
    <h4>Procedure</h4>\
    <ol>\
    <li>Selecteer een bestand om te importeren.</li>\
    <li>Controleer vervolgens hoeveel gebruikers er worden ingeladen</li>\
    <li>Koppel de kolommen uit het bestand aan de gebruikersvelden.</li>\
    <li>Klik importeren, Het bestand wordt eerst gecontroleerd op fouten.</li>\
    <li>Worden er geen fouten gevonden dan worden de gebruikers ingeladen of overschreven als het emailadres bekend is.</li>\
    <li>Nieuwe gebruikers zijn niet gekoppeld aan projecten. Dit doe je zelf via het scherm Rechten.</li>\
    <li>Nieuwe gebruikers hebben nog geen uitnodiging ontvangen. Deze verstuur je zelf.</li>\
    </ol><br/>\
    <h4>Vereisten voor importbestand</h4>\
    <ul>\
    <li>Het is een CSV of Excelbestand</li>\
    <li>De 1e regel bevat de kolomkoppen</li>\
    <li>Drie kolommen met informatie voor <b>Gebruiker</b>, <b>Rol</b> en <b>Emailadres</b> zijn vereist, de kolommen Team en Afdeling optioneel. Overige kolommen zijn toegestaan en worden genegeerd</li>\
    <li>De kolom Rol heeft altijd de waarde \'<b>user</b>\', \'<b>manager</b>\' of \'<b>admin</b>\'. Voor de rol van \'Agent\' wordt \'user\' gebruikt. Let op: geen hoofdletters</li>\
    <li>Alleen de informatie uit de kolommen die gekoppeld worden, worden ook echt ingeladen</li>\
    <li>Alle rijen worden ingeladen (een hele lege rij wordt overgeslagen)</li>\
    <li>De celwaarde wordt ingeladen (je kan dus gewoon Excel-formules gebruiken, maar formatering kan afwijken)</li>\
    </ul><br/>\
    ',

  cases_import: '\
    <h4>Procedure</h4>\
    <ol>\
    <li>Selecteer het bestand dat je wilt importeren</li>\
    <li>Koppel de kolommen uit het bestand aan de vragen</li>\
    <li>Klik op Importeer om de cases te importeren</li>\
    <li>Los eventuele foutmeldingen op en importeer het bestand opnieuw</li>\
    </ol><br/>\
    <h4>Aandachtspunten importbestand</h4>\
    <ul>\
    <li>Bestandstype CSV of Excel</li>\
    <li>Zet kolomnamen in de eerste regel</li>\
    <li>Alleen gekoppelde kolommen worden ingeladen</li>\
    <li>Alle rijen worden ingeladen</li>\
    <li>Lege rijen geven een foutmelding</li>\
    <li>Maximaal 10.000 rijen of 50.000 Antwoorden</li>\
    <li>De celwaarden worden ingeladen (Excelformules hoef je dus niet zelf eerst naar waarden om te zetten)</li>\
    <li>Zet datums in tekst-format, met de volgende Excelformule: <i>=TEKST(A2,"YYYY-MM-DD")</i> voor NL versie en <i>=FORMAT(A2;"YYYY-MM-DD")</i> voor ENG versie</li>\
    </ul><br/>\
    ',

  question_options_import: '\
    <h4>Procedure</h4>\
    <ol>\
    <li>Selecteer een bestand om te importeren.</li>\
    <li>Koppel de kolommen uit het bestand aan de gebruikersvelden.</li>\
    <li>Klik importeren, Het bestand wordt eerst gecontroleerd op fouten.</li>\
    <li>Worden er geen fouten gevonden dan worden alle bestaande antwoorden verwijderd en de nieuwe antwoorden opgevoerd.</li>\
    <li>Als de opties Toelichting, Score of Routering aan staan, wordt het mogelijk ook deze te vullen via het import bestand.</li>\
    <li>Controleer of de import goed is verlopen en bevestig deze met antwoorden opslaan.</li>\
    </ol><br/>\
    <h4>Vereisten voor importbestand</h4>\
    <ul>\
    <li>Het is een CSV of Excelbestand</li>\
    <li>De 1e regel bevat de kolomkoppen</li>\
    <li>Alleen de informatie uit de kolommen die gekoppeld worden, worden ook echt ingeladen</li>\
    <li>Alle rijen worden ingeladen (een lege rij geeft een foutmelding)</li>\
    <li>De cel waarde wordt ingeladen (je kan dus gewoon Excel-formules gebruiken, maar formatering kan afwijken)</li>\
    <li>Score moeten altijd een getal zijn</li>\
    <li>Routering kan bestaan uit de volgende mogelijkheden \'2\', \'B\' of \'2|3|B\' Het Pipeteken wordt gebruikt als scheidingteken bij het opgeven van meerdere routeringen</li>\
    </ul><br/>\
    ',

  extended_search_help: '\
    <h4>Filtervelden</h4>\
    Mis je een zoekveld? Pas dan eerst jouw kolomindeling aan via de knop \'Selecteer kolommen\' in het Cases-scherm.\
    <br/>\
    <h4>Verschillende filters</h4>\
    Afhankelijk van het type vraag zie je verschillende filters:\
    <ul>\
    <li>Dropdown filter: als je meerdere opties aanvinkt, werkt het filter als OF-filter (en niet als EN).</li>\
    <li>Van t/m filter</li>\
    <li>Tekstfilter: toont alleen resultaten die exact aan het zoekwoord voldoen. Het is niet hoofdlettergevoelig.</li>\
    </ul>\
    <br/>\
    <h4>Zoeken op deel van een woord</h4>\
    Als je op een deel van een woord wilt zoeken gebruik je het Joker-teken (<b>%</b>). Je vindt met \'<i>am%</i>\' bijvoorbeeld \'<i>amstel</i>\', en met \'<i>%am%</i>\' ook \'<i>kamer</i>\'\
  '

}
