import Vue from "vue";
import moment from "moment"
import VueMoment from "vue-moment"

import longDateFormatNL from "@/locales/date-formats-nl"
import longDateFormatEN from "@/locales/date-formats-en"

Vue.use(VueMoment, {
  moment
});

// set locale date formats; en-gb | nl | en
moment.updateLocale('nl', {
  longDateFormat : longDateFormatNL
});
moment.updateLocale('en', {
  longDateFormat : longDateFormatEN
});

moment.locale(process.env.VUE_APP_I18N_LOCALE);

export default moment
