import Vue from 'vue'
import VueI18n from 'vue-i18n'
import app_nl from '../locales/nl.json';
import app_en from '../locales/en.json';
import validation_nl from "vee-validate/dist/locale/nl.json";
import validation_en from "vee-validate/dist/locale/en.json";
import help_nl from '@/locales/nl-help.js'
import help_en from '@/locales/en-help.js'

import {merge} from "lodash-es";

let messages_nl = merge({validation: validation_nl.messages}, app_nl)
let messages_en = merge({validation: validation_en.messages}, app_en)
messages_nl.help_bar = help_nl
messages_en.help_bar = help_en

Vue.use(VueI18n);

const i18n = new VueI18n({
  silentFallbackWarn: false,
  // eslint-disable-next-line 
  locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: false,
  messages: {
    nl: messages_nl,
    en: messages_en
  }
});

export default i18n;
