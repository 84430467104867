import axios from '@/config/axios'
import i18n from '@/config/i18n'
import router from '@/config/router'
import store from '@/store'


export const loginToApi = async (credentials) => {
  store.commit('isLoadingButton', true, { root: true })
  store.commit('authState/setGlobalProject', null)

  return await axios.get('/csrf-cookie')
    .then(async () => {
      return await axios.post('/api/login', {
        email: credentials.email,
        password: credentials.password,
        token: credentials.token
      })
        .then(response => {
          store.commit('isLoadingButton', false, { root: true })

          if (response.data.isLoggedin && !response.data.twoFactorAuth) {
            store.commit('authState/setUser', response.data.user)
            store.commit('setShowSnackbar', ['info', i18n.t('auth.welcome')], { root: true })
            router.push({ name: 'cases' })

          } else {
            return response.data
          }
        })
        .catch((error) => {
          console.log(error)
        })
    })
}


export const loginVerifyToApi = async (credentials) => {
  store.commit('isLoadingButton', true, { root: true })
  await axios.post('/api/verify', {
    secret: credentials.secret,
    user: credentials.user
  })
    .then(response => {
      store.commit('isLoadingButton', false, { root: true })
      store.commit('authState/setUser', response.data.user)
      store.commit('setShowSnackbar', ['info', i18n.t('auth.welcome')], { root: true })
      router.push({ name: 'cases' })
    })
    .catch((error) => {
      console.log(error)
    })

}


export const forgotPasswordFromApi = async (data) => {
  store.commit('isLoadingButton', true, { root: true })
  await axios.get('/csrf-cookie')
    .then(async () => {
      await axios.post('/api/forgot-password', {
        email: data.email
      })
        .then(() => {
          store.commit('isLoadingButton', false, { root: true })
          store.commit('authState/setIsReset', true)
        })
    })
    .catch((error) => {
      console.log(error)
    })
}


export const resetPasswordFromApi = async (data) => {
  store.commit('isLoadingButton', true, { root: true })
  return await axios.get('/csrf-cookie')
    .then(async () => {
      return await axios.post('/api/reset-password', {
        token: data.token,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation
      })
        .then(response => {
          store.commit('isLoadingButton', false, { root: true })

          if (!response.data.twoFactorAuth) {
            store.commit('authState/setUser', response.data.user)
            store.commit('setShowSnackbar', ['info', i18n.t('auth.welcome')], { root: true })
            router.push({ name: 'cases' })
          } else {
            return response.data
          }
        })
    })
    .catch((error) => {
      console.log(error)
    })
}


export const reset2FAFromApi = async (data) => {
  store.commit('isLoadingButton', true, { root: true })
  await axios.post('/api/users/mail-reset-2fa', {
    id: data.user
  })
    .then(() => {
      store.commit('isLoadingButton', false, { root: true })
      store.commit('setShowSnackbar', ['info', i18n.t('auth.mailReset2fa')], { root: true })
    })
    .catch((error) => {
      console.log(error)
    })
}


export const logoutFromApi = async () => {
  if (store.getters['authState/isLoggedIn']) {
    store.commit('isLoadingButton', true, { root: true })
    axios.post('/api/logout')
      .then(() => {
        store.commit('authState/setUser', null)
        if (router.currentRoute.name !== 'login' && router.currentRoute.name !== 'reset-password' && router.currentRoute.name !== 'forgot-password') {
          router.push({ name: 'login' })
        }
        store.commit('isLoadingButton', false, { root: true })
      })
      .catch((error) => {
        console.log(error)
      })
  }
}


export const checkAuthFromApi = async () => {
  if (store.getters['authState/isLoggedIn'] && router.currentRoute.name !== 'login' && router.currentRoute.name !== 'reset-password' && router.currentRoute.name !== 'forgot-password') {
    return axios.get(`/api/users/${store.getters['authState/getUser'].id}/check-auth`)
      .then(response => {
        store.commit('authState/setUser', response.data.user)
        return true
      })
      .catch((error) => {
        console.log(error)
      })
  }
}


export const updateAuthFromApi = async () => {
  await axios.get(`/api/users/${store.getters['authState/getUser'].id}/check-auth`)
    .then(response => {
      store.commit('authState/setUser', response.data.user)
    })
    .catch((error) => {
      console.log(error)
    })
}
