import axios from '@/config/axios'
import {difference, filter, keys, map} from 'lodash-es'
import Vue from 'vue'

export default {

  namespaced: true,


  state: {
    elapsedTime: 0,
    details: true,
    cases: [],
    pushCaseId: false,
    case: {},
    history: {},
    status: {},
    questions: [],
    answers: {},
    locks: [],
    lock: {},
    routing: [],
    answerKeys: [],
    deprecatedAnswers: [],
    filterColumns: {},
    filterOptions: {},
    filterQuestions: {},
    pagination: {
      itemsLength: 0,
      itemsPerPage: 0,
      page: 0,
      pageCount: 0,
      pageStart: 0,
      pageStop: 0,
    },
    filterCases: 'allCases'
  },


  mutations: {
    setDetails(state, payload){
      state.details = payload
    },
    setElapsedTime(state, payload){
      state.elapsedTime = payload
    },
    setCases(state, payload){
      state.cases = Array.isArray(payload) ? payload : []
    },
    setPushCaseId(state, payload){
      state.pushCaseId = payload
    },
    setCase(state, payload){
      state.case = payload
    },
    setHistory(state, payload){
      state.history = payload
    },
    setStatus(state, payload){
      state.status = payload
    },
    setQuestions(state, payload){
      state.questions = payload
    },
    setAnswers(state, payload){
      state.answers = payload
      Vue.set(state, 'answerKeys', keys(payload).map(x => parseInt(x, 10)))
    },
    setLocks(state, payload){
      state.locks = Array.isArray(payload) ? payload : []
    },
    setFilterColumns(state, payload){
      state.filterColumns = payload
    },
    setFilterOptions(state, payload){
      state.filterOptions = payload
    },
    setFilterQuestions(state, payload){
      state.filterQuestions = payload
    },
    setPagination(state, payload){
      state.pagination = payload
    },
    setFilterCases(state, payload){
      state.filterCases = payload
    },
    setRouting(state, payload){
      state.routing = Array.isArray(payload) ? payload : []
    },
    reset(state){
      state.details = false
      state.case = {}
      state.history = {}
      state.status = []
      state.answers = {}
      state.routing = []
    }
  },


  getters: {
    getDetails: state => {
      return state.details
    },
    getElapsedTime: state => {
      return state.elapsedTime
    },
    getCases: state => {
      return state.cases
    },
    getOpenCases: state => {
      return filter(state.cases, function (c){
        return c.case_status_type === 'open'
      })
    },
    getPushCaseId: state => {
      return state.pushCaseId
    },
    getCase: state => {
      return state.case
    },
    getHistory: state => {
      return state.history
    },
    getStatus: state => {
      return state.status
    },
    getQuestions: state => {
      return state.questions
    },
    getAnswers: state => {
      return state.answers
    },
    getLocks: state => {
      return state.locks
    },
    getFilterColumns: state => {
      return state.filterColumns
    },
    getFilterOptions: state => {
      return state.filterOptions
    },
    getFilterQuestions: state => {
      return state.filterQuestions
    },
    getPagination: state => {
      return state.pagination
    },
    getFilterCases: state => {
      return state.filterCases
    },
    getRouting: state => {
      return state.routing
    },
    getRoutingKeys: state => {
      return map(filter(state.routing, function (s){
        return s.type === 'question'
      }), 'id')
    },
    getRoutingQuestionKeys: (state) => {
      return map(filter(state.answers, function (s){
        return s.question.status_id === state.status.id
      }), 'question_view_id')
    },
    getRoutingQuestionSourceKeys: (state, getters) => {
      return map(filter(state.questions, function (q){
        return getters.getRoutingKeys.includes(q.id)
      }), 'question_source_id')
    },
    getDeprecatedAnswers: (state, getters) => {
      return difference(getters.getRoutingQuestionKeys, getters.getRoutingKeys)
    }
  },


  actions: {
    async downloadFile({ commit, dispatch }, data){
      commit('isLoading', true, { root: true })
      await axios.post('/api/cases/download', data, { responseType: 'arraybuffer' }).then(response => {
        dispatch('forceFileDownload', { filename: data.file, response: response.data }, { root: true })
      })
    }
  }

}
