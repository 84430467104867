import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSnackbar,{attrs:{"value":_vm.getShowSnackbar,"bottom":"","color":_vm.getTypeSnackbar,"multi-line":"","timeout":"5000"},on:{"input":_vm.hideSnackbar},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"icon":""},on:{"click":_vm.hideSnackbar}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}])},[(_vm.validationErrors)?_c(VList,{attrs:{"dark":"","dense":"","flat":"","tile":"","color":"transparent"}},[_c(VListItemGroup,{attrs:{"no-action":""}},_vm._l((_vm.validationErrors),function(value,key){return _c(VListItem,{key:key,attrs:{"dense":"","inactive":""}},[_vm._v(" "+_vm._s(value)+" ")])}),1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }