<template>
  <v-app class="turfmaster-app" v-if="mountedApp">
    <snackbar/>
    <loader v-if="isLoading"/>
    <navbar v-if="isLoggedIn"/>
    <v-main v-if="Object.keys(getAppStatus).length > 0" :class="serverLocation">
      <router-view/>
    </v-main>
    <v-sheet v-if="Object.keys(getAppStatus).length === 0" height="100vh">
      <v-container fluid>
        <span>{{ $t('auth.no_app') }}</span>
      </v-container>
    </v-sheet>
  </v-app>
</template>

<script>
import Loader from './ui/Loader';
import Snackbar from './ui/alerts/Snackbar';
import Navbar from './ui/admin/Navbar';
import {mapGetters} from "vuex";
import "@/config/vee-validate"
import {checkAuthFromApi, getAppStatusFromApi, unlockCaseFromApi, logoutFromApi} from "@/api";

export default {
  name: 'App',
  components: {
    Loader,
    Snackbar,
    Navbar
  },
  computed: {
    ...mapGetters({
      getAppStatus: 'getAppStatus',
      isLoading: 'isLoading',
      getUser: 'authState/getUser',
      isLoggedIn: 'authState/isLoggedIn',
      getDetails: 'caseState/getDetails',
      getFormDirty: 'getFormDirty',
      getCase: 'caseState/getCase',
    }),

    serverLocation(){
      return process.env.VUE_APP_SERVER_LOCATION || 'local';
    },

    toggleDetails: {
      get: function (){
        return this.getDetails
      },
      set: function (value){
        this.$store.commit('caseState/setDetails', value)
      }
    },

    mountedApp() {
      return this.isLoggedIn || (!this.isLoggedIn && !this.$route.meta.requiresAuth)
    }
  },
  methods: {

    // TODO https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    beforeunload(event){
      if ('id' in this.getCase) {
        unlockCaseFromApi(this.getCase.id)
      }
      if (this.getFormDirty && event !== 'undefined') {
        event.returnValue = this.$t('general.notsaved');
      }
      // give the event some time to do his actions
      for (var i = 0; i < 500000000; i++) { }
    }
  },

  created(){

    getAppStatusFromApi().then(() => {
      if (this.getAppStatus.redirect_url !== false) {
        window.location = this.getAppStatus.redirect_url
      }
      this.$root.$i18n.locale = this.getAppStatus.app_language.toLowerCase()
      this.$vuetify.lang.current = this.getAppStatus.app_language.toLowerCase()

      document.documentElement.setAttribute('lang', this.getAppStatus.app_language.toLowerCase())

      // start listening for permissions through websockets
      this.$echo.channel(`taskmaster_${this.getAppStatus.id}_permissions`).listen('UserUpdated', () => {
        checkAuthFromApi().then(() => {
          if (!this.getUser) return
          if (!this.getUser.isActive) logoutFromApi()
        })
      });
    }).catch((error) => {
      console.warn(error)
      logoutFromApi()
    });

    window.addEventListener('beforeunload', this.beforeunload)
  },

  beforeDestroy(){
    this.beforeunload()
  }
};
</script>

<style lang="scss">
@import './style/_base.scss';

.local {
  border-right: 2px solid green;
}

.staging {
  border-right: 2px solid red;
}

.production {
  border-right: 2px solid #dadada;
}

.production-edge {
  border-right: 2px solid #c4daf5;
}
</style>
