export default {

  namespaced: true,


  state: {
    projects: [],
    project: {},
    statuses: [],
    allQuestions: [],
    sortBy: 'updated_at',
    useColumns: [],
  },


  mutations: {
    setProjects(state, payload){
      state.projects = payload
    },
    setProject(state, payload){
      state.project = payload
    },
    setProjectColumns(state, payload){
      state.project.columns = payload.columns
      state.project.updated_at = payload.updated_at
    },
    setStatuses(state, payload){
      state.statuses = payload
    },
    setAllQuestions(state, payload){
      state.allQuestions = payload
    },
    setSortBy(state, payload){
      state.sortBy = payload
    },
    setUseColumns(state, payload){
      state.useColumns = payload
    }
  },


  getters: {
    getProjects: state => {
      return state.projects
    },
    getProject: state => {
      return state.project
    },
    getStatuses: state => {
      return state.statuses
    },
    getAllQuestions: state => {
      return state.allQuestions
    },
    getSortBy: state => {
      return state.sortBy
    },
    getUseColumns: state => {
      return state.useColumns
    }
  }

}
