import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from '@/config/router'
import store from '@/store'
import vuetify from '@/config/vuetify'
import axios from '@/config/axios'
import i18n from '@/config/i18n'
import moment from '@/config/vue-moment'
import VueClipboard from 'vue-clipboard2'
import {TiptapVuetifyPlugin} from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import {initSentry} from './config/sentry'

import VueEcho from "vue-echo-laravel"

// get laravel websocket events
// eslint-disable-next-line
window.io = require('socket.io-client');

let url = new URL(window.location.href).hostname
// eslint-disable-next-line
let host = process.env.VUE_APP_WSS_URL || `//${url}`;

Vue.use(VueEcho, {
  broadcaster: 'socket.io',
  // eslint-disable-next-line
  host: `${host}:${process.env.VUE_APP_WSS_PORT}`,
  transports: ['websocket', 'polling'],
});

Vue.filter('truncate', function (value, limit){
  if (value && value.length > limit) {
    value = value.substring(0, (limit - 3)) + '...';
  }
  return value
});

Vue.filter('localMoment', function (date, format){
  return moment.utc(date).local().format(format);
})

Vue.filter('yesno', function (value){
  return value ? i18n.t('general.yes') : i18n.t('general.no');
});

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard);

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
});

Vue.config.productionTip = false;

if (process.env.VUE_APP_ENVIRONMENT !== 'development') {
  initSentry()
}

new Vue({
  router,
  store,
  vuetify,
  axios,
  i18n,
  render: h => h(App),
}).$mount('#app');
