<template>
  <v-overlay
    v-if="$store.getters.isLoading"
    center
  >
    <div class="text-center">
      <v-progress-circular
        indeterminate
        size="32"
        color="primary"
      />
    </div>
    <div class="text-center mt-2">
      {{ LoadText }}
    </div>
  </v-overlay>
</template>

<script>

  export default {
    name: "Loader",
    computed: {
      LoadText() {
        return typeof this.$store.getters.isLoading === "boolean" ? '' : this.$store.getters.isLoading;
      }
    }
  }
</script>