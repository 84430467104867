export default {

  namespaced: true,


  state: {
    permission: {},
    permissions: [],
    bulk: false
  },


  mutations: {
    setPermission(state, payload) {
      state.permission = payload
    },
    setPermissions(state, payload) {
      state.permissions = payload
    },
    setBulk(state, payload) {
      state.bulk = payload
    }
  },


  getters: {
    getPermission: state => {
      return state.permission
    },
    getPermissions: state => {
      return state.permissions
    },
    getBulk: state => {
      return state.bulk
    }
  }

}
