<template>
  <v-dialog
    :value="showlocal"
    color="white"
    max-width="700"
    persistent
  >
    <v-card min-height="45vh">
      <v-toolbar flat>
        <v-toolbar-title>{{ $t('user.your_profile') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="closeDetail"
          tabindex="-1"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container
        fluid
        pt-0
      >
        <v-tabs
          color="primary"
          show-arrows
          v-model="tab"
        >
          <v-tab
            class="text-left pa-0 ml-0 mr-5"
            tabindex="-1"
          >
            {{ $t('user.user') }}
          </v-tab>
          <v-tab
            class="pa-0 mx-5"
            tabindex="-1"
          >
            {{ $t('permissions.permissions') }}
          </v-tab>
          <v-tab
            class="pa-0 ml-5"
            tabindex="-1"
          >
            {{ $t('auth.resetPassword') }}
          </v-tab>

          <v-tabs-items
            v-model="tab"
            touchless
          >
            <v-tab-item>
              <v-card-text>
                <ValidationObserver ref="formuser">
                  <v-form @submit.prevent>
                     <!-- Alert if the form is dirty -->
                    <form-dirty
                      :value="$t('user.notsaved')"
                      @closeDetail="closeDetail(true)"
                    />
                    <v-row>
                      <v-col cols="12">
                        <ValidationProvider
                          :name="$t('user.user')"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            tabindex="0"
                            v-model="currentUser.name"
                            filled
                            dense
                            :label="$t('user.user')"
                            hide-details="auto"
                            :error-messages="errors"
                            :loading="isLoadingButton"
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <ValidationProvider
                          :name="$t('user.email')"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            disabled
                            v-model="currentUser.email"
                            filled
                            dense
                            :label="$t('user.email')"
                            hide-details="auto"
                            :error-messages="errors"
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <ValidationProvider
                          :name="$t('user.role')"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-select
                            disabled
                            v-model="currentUser.role"
                            :items="roles"
                            filled
                            dense
                            :label="$t('user.role')"
                            hide-details="auto"
                            :error-messages="errors"
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="currentUser.team"
                          :items="optionsTeam"
                          filled
                          dense
                          :label="$t('user.team')"
                          hide-details="auto"
                          ref="team"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="currentUser.department"
                          :items="optionsDepartment"
                          filled
                          dense
                          :label="$t('user.department')"
                          hide-details="auto"
                          ref="department"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-spacer />
                      <v-tooltip
                        max-width="50%"
                        left
                      >
                        <template #activator="{ on }">
                          <v-btn
                            tabindex="0"
                            class="mx-2"
                            type="submit"
                            fab
                            color="primary"
                            v-on="on"
                            @click="submitUser"
                            :loading="isLoadingButton"
                          >
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('user.storeUser') }}</span>
                      </v-tooltip>
                    </v-row>
                  </v-form>
                </ValidationObserver>
              </v-card-text>
            </v-tab-item>

            <v-tab-item>
              <v-data-table
                :headers="headers"
                :items="items"
                :mobile-breakpoint="0"
                :items-per-page="-1"
                fixed-header
                hide-default-footer
                height="450"
                dense
              >
                <template #item="{item}">
                  <tr>
                    <td class="truncate min-width">
                      <v-tooltip
                        max-width="50%"
                        right
                        bottom
                      >
                        <template #activator="{ on }">
                          <span v-on="on">
                            {{ item.id }} - {{ item.project }}
                          </span>
                        </template>
                        <span>{{ item.id }} - {{ item.project }}</span>
                      </v-tooltip>
                    </td>

                    <td>
                      <v-tooltip
                        max-width="50%"
                        right
                        bottom
                      >
                        <template #activator="{ on }">
                          <span v-on="on">
                            <v-icon
                              class="px-1"
                              v-if="item.permissions.canViewAllCases"
                              color="primary"
                              dense
                            >mdi-eye</v-icon>
                            <v-icon
                              class="px-1"
                              v-if="!item.permissions.canViewAllCases"
                              dense
                              :class="[{ 'text--disabled': !item.isActive }]"
                            >mdi-eye-minus-outline</v-icon>
                          </span>
                        </template>
                        <span v-if="item.permissions.canViewAllCases">{{ $t('permissions.canViewAllCases') }}</span>
                        <span v-else>{{ $t('permissions.canNotViewAllCases') }}</span>
                      </v-tooltip>

                      <v-tooltip
                        max-width="50%"
                        right
                        bottom
                      >
                        <template #activator="{ on }">
                          <span v-on="on">
                            <v-icon
                              class="px-1"
                              v-if="item.permissions.canEditCases && !item.permissions.canModifyAllCases"
                              color="primary"
                              dense
                            >mdi-pencil-outline</v-icon>
                            <v-icon
                              class="px-1"
                              v-if="item.permissions.canModifyAllCases"
                              color="primary"
                              dense
                            >mdi-pencil</v-icon>
                            <v-icon
                              class="px-1"
                              v-if="!item.permissions.canEditCases"
                              dense
                              :class="[{ 'text--disabled': !item.isActive }]"
                            >mdi-pencil-off-outline</v-icon>
                          </span>
                        </template>
                        <span v-if="item.permissions.canModifyAllCases">{{ $t('permissions.canModifyAllCases') }}</span>
                        <span v-else-if="item.permissions.canEditCases">{{ $t('permissions.canEditCases') }}</span>
                        <span v-else>{{ $t('permissions.canNotEditCases') }}</span>
                      </v-tooltip>

                      <v-tooltip
                        max-width="50%"
                        right
                        bottom
                      >
                        <template #activator="{ on }">
                          <span v-on="on">
                            <v-icon
                              class="px-1"
                              v-if="item.permissions.canRemoveAllCases"
                              color="primary"
                              dense
                            >mdi-trash-can</v-icon>
                            <v-icon
                              class="px-1"
                              v-else-if="item.permissions.canRemoveCases"
                              color="primary"
                              dense
                            >mdi-trash-can-outline</v-icon>
                            <v-icon
                              class="px-1"
                              v-else
                              dense
                              :class="[{ 'text--disabled': !item.isActive }]"
                            >mdi-delete-off-outline</v-icon>
                          </span>
                        </template>
                        <span v-if="item.permissions.canRemoveAllCases">{{ $t('permissions.canRemoveAllCases') }}</span>
                        <span v-else-if="item.permissions.canRemoveCases">{{ $t('permissions.canRemoveCases') }}</span>
                        <span v-else>{{ $t('permissions.canNotRemoveCases') }}</span>
                      </v-tooltip>

                      <v-tooltip
                        max-width="50%"
                        right
                        bottom
                      >
                        <template #activator="{ on }">
                          <span v-on="on">
                            <v-icon
                              class="px-1"
                              v-if="item.permissions.canAddCases"
                              color="primary"
                              dense
                            >mdi-plus-circle</v-icon>
                            <v-icon
                              class="px-1"
                              v-if="!item.permissions.canAddCases"
                              dense
                              :class="[{ 'text--disabled': !item.isActive }]"
                            >mdi-plus-circle-outline</v-icon>
                          </span>
                        </template>
                        <span v-if="item.permissions.canAddCases">{{ $t('permissions.canAddCases') }}</span>
                        <span v-else>{{ $t('permissions.canNotAddCases') }}</span>
                      </v-tooltip>

                      <v-tooltip
                        max-width="50%"
                        right
                        bottom
                      >
                        <template #activator="{ on }">
                          <span v-on="on">
                            <v-icon
                              class="px-1"
                              v-if="item.permissions.canUnlockCases"
                              color="primary"
                              dense
                            >mdi-lock-open</v-icon>
                            <v-icon
                              class="px-1"
                              v-if="!item.permissions.canUnlockCases"
                              dense
                              :class="[{ 'text--disabled': !item.isActive }]"
                            >mdi-lock-open-outline</v-icon>
                          </span>
                        </template>
                        <span v-if="item.permissions.canUnlockCases">{{ $t('permissions.canUnlockCases') }}</span>
                        <span v-else>{{ $t('permissions.canNotUnlockCases') }}</span>
                      </v-tooltip>

                      <v-tooltip
                        max-width="50%"
                        right
                        bottom
                      >
                        <template #activator="{ on }">
                          <span v-on="on">
                            <v-icon
                              class="px-1"
                              v-if="item.permissions.CanNotEditStatus === null"
                              color="primary"
                              dense
                            >mdi-circle-slice-8</v-icon>
                            <v-icon
                              class="px-1"
                              v-else-if="Array.isArray(item.permissions.CanNotEditStatus) && item.permissions.CanNotEditStatus.length < item.statuses.length"
                              color="primary"
                              dense
                            >mdi-circle-slice-4</v-icon>
                            <v-icon
                              class="px-1"
                              v-else-if="Array.isArray(item.permissions.CanNotEditStatus) && item.permissions.CanNotEditStatus.length === item.statuses.length"
                              dense
                            >mdi-circle-outline</v-icon>
                          </span>
                        </template>
                        <span v-if="item.permissions.CanNotEditStatus === null">{{
                          $t('permissions.CanNotEditStatusAll')
                        }}</span>
                        <span
                          v-else-if="Array.isArray(item.permissions.CanNotEditStatus) && item.permissions.CanNotEditStatus.length < item.statuses.length"
                        >{{
                          $t('permissions.CanNotEditStatusHalf')
                        }}</span>
                        <span
                          v-else-if="Array.isArray(item.permissions.CanNotEditStatus) && item.permissions.CanNotEditStatus.length === item.statuses.length"
                        >{{
                          $t('permissions.CanNotEditStatusNone')
                        }}</span>
                      </v-tooltip>

                      <v-tooltip
                        max-width="50%"
                        right
                        bottom
                      >
                        <template #activator="{ on }">
                          <span v-on="on">
                            <v-icon
                              class="px-1"
                              v-if="item.permissions.SelectedDashboards === null || validSelectedDashboards(item.dashboards,item.permissions.SelectedDashboards).length === 0"
                              dense
                            >mdi-chart-line</v-icon>
                            <v-icon
                              class="px-1"
                              v-else-if="Array.isArray(validSelectedDashboards(item.dashboards,item.permissions.SelectedDashboards)) && validSelectedDashboards(item.dashboards,item.permissions.SelectedDashboards).length < item.dashboards.length"
                              color="primary"
                              dense
                            >mdi-chart-areaspline</v-icon>
                            <v-icon
                              class="px-1"
                              color="primary"
                              v-else-if="Array.isArray(validSelectedDashboards(item.dashboards,item.permissions.SelectedDashboards)) && validSelectedDashboards(item.dashboards,item.permissions.SelectedDashboards).length >= item.dashboards.length"
                              dense
                            >mdi-chart-areaspline-variant</v-icon>
                          </span>
                        </template>
                        <span v-if="item.permissions.SelectedDashboards === null || validSelectedDashboards(item.dashboards,item.permissions.SelectedDashboards).length === 0">{{ $t('permissions.SelectedDashboardsNone') }}</span>
                        <span v-else-if="Array.isArray(validSelectedDashboards(item.dashboards,item.permissions.SelectedDashboards)) && validSelectedDashboards(item.dashboards,item.permissions.SelectedDashboards).length < item.dashboards.length">{{ $t('permissions.SelectedDashboardsHalf') }}</span>
                        <span v-else-if="Array.isArray(validSelectedDashboards(item.dashboards,item.permissions.SelectedDashboards)) && validSelectedDashboards(item.dashboards,item.permissions.SelectedDashboards).length >= item.dashboards.length">{{ $t('permissions.SelectedDashboardsAll') }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>

            <v-tab-item>
              <v-card-text>
                <v-row v-if="getErrors">
                  <v-col cols="12">
                    <v-banner
                      dark
                      color="error"
                    >
                      <div
                        v-for="(item, i) in getErrors"
                        :key="i"
                      >
                        {{ item[0] }}
                      </div>
                    </v-banner>
                  </v-col>
                </v-row>

                <ValidationObserver ref="formpassword">
                  <v-form @submit.prevent>
                    <v-row>
                      <v-col cols="12">
                        <ValidationProvider
                          :name="$t('user.email')"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            disabled
                            v-model="userlocal.email"
                            filled
                            dense
                            :label="$t('user.email')"
                            hide-details="auto"
                            :error-messages="errors"
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <ValidationProvider
                          :name="$t('user.password')"
                          rules="required|password"
                          vid="confirmation"
                          :debounce="500"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            :type="show1 ? 'text' : 'password'"
                            v-model="new_password"
                            name="new_pw"
                            filled
                            dense
                            @keyup="capslockState"
                            :label="$t('user.newPassword')"
                            hide-details="auto"
                            :error-messages="errors"
                          >
                            <template slot="append">
                              <v-tooltip
                                max-width="50%"
                                right
                              >
                                <template #activator="{ on }">
                                  <div
                                    class="d-inline"
                                    v-on="on"
                                  >
                                    <v-icon v-if="caps">
                                      mdi-keyboard-caps
                                    </v-icon>
                                  </div>
                                </template>
                                <span>{{ $t('auth.CapsLock') }}</span>
                              </v-tooltip>
                              <v-icon
                                v-html="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                @click="show1 = !show1"
                              />
                            </template>
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <ValidationProvider
                          :name="$t('user.password')"
                          rules="required|confirmed:confirmation"
                          :debounce="500"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            :type="show2 ? 'text' : 'password'"
                            v-model="password_confirmation"
                            filled
                            dense
                            name="confirm_pw"
                            @keyup="capslockState"
                            :label="$t('user.passwordConfirmation')"
                            hide-details="auto"
                            :error-messages="errors"
                          >
                            <template slot="append">
                              <v-tooltip
                                max-width="50%"
                                right
                              >
                                <template #activator="{ on }">
                                  <div
                                    class="d-inline"
                                    v-on="on"
                                  >
                                    <v-icon v-if="caps">
                                      mdi-keyboard-caps
                                    </v-icon>
                                  </div>
                                </template>
                                <span>{{ $t('auth.CapsLock') }}</span>
                              </v-tooltip>
                              <v-icon
                                v-html="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                @click="show2 = !show2"
                              />
                            </template>
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-spacer />
                      <v-speed-dial
                        direction="top"
                        open-on-hover
                        transition="slide-y-reverse-transition"
                      >
                        <template #activator>
                          <v-tooltip
                            max-width="50%"
                            left
                          >
                            <template #activator="{ on }">
                              <v-btn
                                class="mx-2"
                                type="submit"
                                fab
                                color="primary"
                                v-on="on"
                                @click="resetPassword"
                                :loading="isLoadingButton"
                              >
                                <v-icon>mdi-check</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('general.submit') }}</span>
                          </v-tooltip>
                        </template>
                      </v-speed-dial>
                    </v-row>
                  </v-form>
                </ValidationObserver>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {cloneDeep, filter, groupBy, map, remove} from 'lodash-es'
import helper from '@/helpers/helper.js';
import {updateUserToApi, getUsersFromApi} from "@/api";
import FormDirty from "@/ui/alerts/FormDirty"

export default {
  name: "Profile",
  props: {
    'show': {
      type: Boolean,
      default: false
    },
    'user': {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      search: '',
      found: 0,
      show1: false,
      show2: false,
      caps: false,
      tab: 0,
      currentUser: {
        name: this.user.name,
        email: this.user.email,
        role: this.user.role,
        team: this.user.team,
        department: this.user.department,
      },
      headers: [
        {
          text: this.$t('project.project'),
          sortable: true,

          value: 'project',
        },
        {
          text: this.$t('permissions.permissions'),
          sortable: false,
          width: 275,
          value: 'permissions',
        }
      ],
      new_password: "",
      password_confirmation: ""
    }
  },
  components: {
    FormDirty,
  },
  computed: {
    ...mapGetters({
      isLoadingButton: 'isLoadingButton',
      getErrors: 'authState/getErrors',
      getUsers: 'userState/getUsers',
      getFormDirty: 'getFormDirty',
      getFormDirtyAlert: 'getFormDirtyAlert',
    }),
    roles() {
      return helper.globals.roles;
    },
    showlocal: {
      get: function () {
        return this.show
      },
      set: function (value) {
        this.$emit('update:show', value)
      }
    },
    userlocal: {
      get: function () {
        return this.user
      },
      set: function (value) {
        this.$emit('update:user', value)
      }
    },
    items() {
      let items = []
      this.user.projects.forEach(i => {
        items.push({
          'id': i.id,
          'dashboards': i.dashboards,
          'project': i.project,
          'statuses': i.statuses,
          'permissions': i.permissions[0],
        })
      })
      return items
    },
    optionsTeam() {
      let users_groupedby = groupBy(this.getUsers, function (n) {
        return n.team;
      });
      let items = Object.keys(users_groupedby);
      return remove(items, function (n) {
        return n != 'null';
      });
    },
    optionsDepartment() {
      let users_groupedby = groupBy(this.getUsers, function (n) {
        return n.department;
      });
      let items = Object.keys(users_groupedby);
      return remove(items, function (n) {
        return n != 'null';
      });
    }
  },
  methods: {
    validSelectedDashboards(dashboards, selected) {
      let available = map(dashboards, 'id')
      return filter(selected, (id) => {
        return available.includes(id)
      })
    },
    capslockState(e) {
      this.caps = false;
      if (typeof e.getModifierState != 'function') {
        return false;
      }
      if (e.getModifierState("CapsLock")) {
        this.caps = true;
      }
    },
    closeDetail(force = false) {
      if (force === true) {
          this.clearCurrentUser()
          this.$store.commit('setFormDirty', false, {root: true})
          this.$store.commit('setFormDirtyAlert', false, {root: true})
          this.showlocal = false
        } else if (this.getFormDirty) {
          this.$store.commit('setFormDirtyAlert', true, {root: true})
        } else {
          this.showlocal = false
        }
    },
    checkLocalUser() {
      if (this.userlocal.name === this.currentUser.name &&
          this.userlocal.email === this.currentUser.email &&
          this.userlocal.role === this.currentUser.role &&
          this.userlocal.team === this.currentUser.team &&
          this.userlocal.department === this.currentUser.department
      ) {
        return true
      }
      return false
    },
    updateLocalUser() {
      this.userlocal.name = this.currentUser.name
      this.userlocal.email = this.currentUser.email
      this.userlocal.role = this.currentUser.role
      this.userlocal.team = this.currentUser.team
      this.userlocal.department = this.currentUser.department
    },
    clearCurrentUser() {
      this.currentUser.name = this.userlocal.name
      this.currentUser.email = this.userlocal.email
      this.currentUser.role = this.userlocal.role
      this.currentUser.team = this.userlocal.team
      this.currentUser.department = this.userlocal.department
    },
    submitUser() {
      this.$refs.formuser.validate().then(success => {
        if (!success) {
          return;
        }
        // update userlocal with form values
        this.updateLocalUser()

        // clear dirty flag
        this.$store.commit('setFormDirty', false, { root: true })
        this.$store.commit('setFormDirtyAlert', false, { root: true })

        updateUserToApi({user: this.userlocal})
        
        this.$nextTick(() => {
          this.$refs.formuser.reset();
          this.closeDetail()
        });
      });
    },
    resetPassword() {
      this.$refs.formpassword.validate().then(success => {
        if (!success) {
          return;
        }
        let userWithPass = cloneDeep(this.userlocal)
        userWithPass.password = this.new_password;
        updateUserToApi({user: userWithPass}).then(response => {
          this.userlocal = response
          this.new_password = null
          this.password_confirmation = null
          this.tab = 0
        });
        this.$nextTick(() => {
          this.$refs.formpassword.reset();
        });
      });
    }
  },

  watch:{
    showlocal: {
      handler(value){
        if (value) {
          getUsersFromApi()
        }
      },
      immediate: true
    },
    currentUser: {
      handler: function () {
        if (this.checkLocalUser()) {
          this.$store.commit('setFormDirty', false, {root: true})
        } else {
          this.$store.commit('setFormDirty', true, {root: true})
        }
      },
      deep: true,
      immediate: false
    },
  }
}
</script>

<style scoped>
/deep/.min-width {
  min-width: 400px;
}
</style>
